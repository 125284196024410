import React, { useContext } from "react";
import "./styles/CorporateData.scss";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import UserContext from "../../context/userContext";
import InfoPaper from "../../App/ProjectSheet/Componentes/InfoPaper/InfoPaper";
import ProjectSheetTitle from "../../App/ProjectSheet/Componentes/Title/ProjectSheetTitle";
import ContentInfo from "../../App/ProjectSheet/Componentes/ContentInfo/ContentInfo";
import TableLoading from "../../components/TableLoading/TableLoading";
import { formatStringToCNPJ } from "../../shared/utils/formatStringToCNPJ";
import AppContext from "../../context/appContext";
import EditModals from "./EditModals/EditModals";
import { formatPhonesToString } from "../../shared/utils/formatPhonesToString";
import SimpleButton from "../../components/SimpleButton/SimpleButton";

const CorporateData = () => {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const me = userContext?.user?.me;
  const {
    cnpj,
    corporateName,
    name,
    phones,
    ancine,
    stateRegistration,
    owner,
  } = me || {};
  const { city, complement, district, number, state, streetName } =
    me?.address || {};

  if (!userContext.isLogged || !me) return <TableLoading />;

  return (
    <div className="corporateData">
      <Breadcrumb>
        <span className="corporateName">{userContext?.user?.me?.name}</span>
      </Breadcrumb>

      <div className="mainArea">
        <div className="container">
          <div className="leftInfo">
            <InfoPaper
              hasIco
              handleClick={() =>
                appContext.setCurrentStepBox("editCorporateData")
              }
            >
              <div className="contentTitleTag">
                <ProjectSheetTitle title="Dados da Produtora" />
                <ContentInfo label="NÚMERO ANCINE" value={ancine} />
                <ContentInfo label="RAZÃO SOCIAL" value={corporateName} />
                <ContentInfo label="NOME FANTASIA" value={name} />
                <ContentInfo
                  label="CNPJ"
                  value={formatStringToCNPJ(cnpj) || "-"}
                />
                <ContentInfo
                  label="INSCRIÇÃO ESTADUAL"
                  value={stateRegistration}
                />
                <ContentInfo label="NOME DO RESPONSÁVEL" value={owner?.name} />
                <ContentInfo label="EMAIL" value={owner?.email} />
                <ContentInfo
                  label="TELEFONE"
                  value={formatPhonesToString(phones)}
                />
              </div>
            </InfoPaper>

            <InfoPaper
              hasIco
              handleClick={() => appContext.setCurrentStepBox("editAddress")}
            >
              <div className="contentTitleTag">
                <ProjectSheetTitle title="Endereço" />
                <ContentInfo label="ENDEREÇO" value={streetName || "-"} />
                <ContentInfo label="NÚMERO" value={number || "-"} />
                <ContentInfo label="COMPLEMENTO" value={complement || "-"} />
                <ContentInfo label="BAIRRO" value={district || "-"} />
                <ContentInfo label="CIDADE" value={city || "-"} />
                <ContentInfo label="ESTADO" value={state || "-"} />
              </div>
            </InfoPaper>

            <InfoPaper>
              <div className="passwordArea">
                <ProjectSheetTitle title="Senha de acesso" />

                <SimpleButton
                  text="Alterar senha"
                  classButton="buttonBlue extraLarge"
                  clickEvent={() =>
                    appContext.setCurrentStepBox("changePassword")
                  }
                />
              </div>
            </InfoPaper>
          </div>

          <div className="rightInfo">
            {/* <InfoCards
              callerIcon={<EditIcon />}
              hasBaseboard={true}
              title="Apresentação"
              handleEvent={() =>
                appContext.setCurrentStepBox("editPresentation")
              }
            >
              <p className="presentationText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque vel porta est, at dictum ante. Suspendisse eleifend
                massa vel velit gravida, at volutpat ligula euismod. Sed sed
                dapibus neque. Suspendisse faucibus nisl sed eros congue
                elementum. Quisque gravida, sapien in luctus facilisis, metus
                nisl tempus ante, nec auctor est dolor non enim. Donec non mi eu
                quam tempor consequat sed vitae mauris. Vestibulum auctor mauris
                vel leo elementum, at ornare nulla venenatis.
              </p>
            </InfoCards> */}

            {/* <InfoCards
              callerIcon={<CreatorIcon color="#000" />}
              hasBaseboard={true}
              title="Usuários"
              handleEvent={() => appContext.setCurrentStepBox("createUser")}
              handleEvent={() => {}}
            >
              <div className="usersList">
                {Array.from(Array(5)).map(() => (
                  <div className="userInfoItem">
                    <div className="header">
                      <span className="info mainInfo">Luíza Cardoso</span>
                      <div onClick={() => {}}>
                        <EditIcon />
                      </div>
                    </div>

                    <span className="info">luiza.cardoso@planob.com.br</span>
                    <span className="info">21 99999-9999</span>
                  </div>
                ))}
              </div>
            </InfoCards> */}
          </div>
        </div>
      </div>

      <EditModals data={me} />
    </div>
  );
};

export default CorporateData;
