import React, { useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import "./styles/Login.scss";
import UserContext from "../../context/userContext";
import AppContext from "../../context/appContext";
import ResetMyPassword from "../ResetMyPassword/ResetMyPassword";
import ChangeMyPassword from "../ChangeMyPassword/ChangeMyPassword";
import ScriptBox from "../ScriptBox/ScriptBox";
import CheckIcon from "../VectorIcons/CheckIcon";
import InfoIcon from "../VectorIcons/InfoIcon";

import ResetSucessBox from "./ResetSucessBox/ResetSucessBox";

const Login = ({ tokenParam }) => {
  const [state, setState] = useState({});
  const [token, setToken] = useState(null);
  const [expired, setExpired] = useState(false);
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const recaptchaRef = React.createRef();

  const history = useHistory();

  const handleChangeLogin = (ev) => {
    setState({
      ...state,
      [ev.target.name]: ev.target.value,
      captcha: token,
    });
  };

  function onChange(value) {
    console.log(value);
    setToken(value);
    setState({
      ...state,
      captcha: value,
    });
  }

  useEffect(() => {
    !!tokenParam && appContext.setCurrentStepBox("changeMyPass");
    if (window.location.search.includes("expired-token")) {
      setExpired(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenParam]);

  return (
    <div id="LoginPortal" className="resetMyPassword">
      <div className="accessSystem">Acesse o sistema</div>

      <div className="formItemPortal">
        <form
          onSubmit={(ev) => {
            recaptchaRef.current.reset();
            return userContext.makeLogin(ev, state);
          }}
        >
          <input
            type="text"
            onChange={handleChangeLogin}
            autoComplete="off"
            name="cnpj"
            placeholder="CNPJ"
          />
          <input
            type="password"
            onChange={handleChangeLogin}
            autoComplete="off"
            name="password"
            placeholder="Senha"
          />
          <div className="formRecaptcha">
            <ReCAPTCHA
              sitekey={window.__.auth.captcha_key}
              onChange={onChange}
              ref={recaptchaRef}
            />
          </div>
          <button>ENTRAR</button>
        </form>
        <p
          className="textResetMyPassword"
          onClick={() => appContext.setCurrentStepBox("resetMyPassword")}
        >
          Esqueci minha senha
        </p>
      </div>

      {appContext.current.step === "resetMyPassword" && !tokenParam && (
        <ScriptBox
          open={appContext.current.step === "resetMyPassword"}
          title="Resetar minha senha"
          size="small"
        >
          <ResetMyPassword />
        </ScriptBox>
      )}
      {appContext.current.step === "sucessResetPassword" && (
        <ResetSucessBox
          open={appContext.current.step === "sucessResetPassword"}
          size="small"
          removePadding
        >
          <div className="resetSuccessHeader">
            <div className="resetSuccessIcon">
              <CheckIcon color="#ffffff" size={60} />
            </div>
          </div>
          <div className="resetSuccessBody">
            <p className="resetSuccessText">
              Você receberá um e-mail com um link para criar uma nova senha.
            </p>
            <button onClick={() => appContext.setCurrentStepBox("closed")}>
              Fechar
            </button>
          </div>
        </ResetSucessBox>
      )}
      {expired && (
        <ResetSucessBox open={expired} size="small" removePadding>
          <div className="resetSuccessHeader">
            <InfoIcon color="#ffffff" size={80} />
          </div>
          <div className="resetSuccessBody">
            <p className="resetSuccessTitle">Sua sessão expirou!</p>
            <p className="resetSuccessText">
              Por favor, retorne para o início e faça login novamente.
            </p>
            <button
              onClick={() => {
                setExpired(false);
                history.push("/");
              }}
            >
              Fazer login novamente
            </button>
          </div>
        </ResetSucessBox>
      )}
      {(appContext.current.step === "changeMyPass" ||
        appContext.current.step === "resetMyPassword") &&
        !!tokenParam && (
          <ScriptBox
            open={
              (appContext.current.step === "changeMyPass" ||
                appContext.current.step === "resetMyPassword") &&
              !!tokenParam
            }
            title="Redefinir senha"
            size="large"
          >
            <ChangeMyPassword tokenParam={tokenParam} />
          </ScriptBox>
        )}
    </div>
  );
};

export default Login;
