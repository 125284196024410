import React, { useRef, useEffect } from "react";
import "./styles/RegulationAndTerms.scss";

const RegulationAndTerms = (props) => {
  const { bgColor = "#ffffff", goToRef } = props;

  const privacyPoliciesRef = useRef(null);

  useEffect(() => {
    if (goToRef === "privacyPoliciesRef") {
      privacyPoliciesRef.current.scrollIntoView();
    }
  });

  return (
    <div
      style={{ background: bgColor }}
      className="contentRegulation container"
    >
      <p className="title">TERMO DE USO E OUTRAS AVENÇAS</p>

      <p className="text">
        O presente Termo regulamenta a utilização do PORTAL GLOBO, destinado à
        disponibilização gratuita, pela PRODUTORA para à GLOBO COMUNICAÇÃO E
        PARTICIPAÇÕES S.A., por sua filial localizada à Avenida das Américas, nº
        1650, Bloco 1 Salas 304 a 308, Bloco 02 Salas 103 a 111, 118, 119, 121,
        125, 126 e 301 a 310, Bloco 03 Salas 101 a 104, 201 a 204 e 301 a 304,
        Barra da Tijuca, Rio de Janeiro, RJ, CEP: 22640- 101, inscrita no
        CNPJ/ME sob o nº 27.865.757/0083-40 (“GLOBO”) e para à NBCUNIVERSAL
        NETWORKS INTERNATIONAL BRASIL PROGRAMADORA S.A., localizada na Avenida
        das Américas, nº 1650, Bloco 04, Salas 201 a 204 e 228 a 233, Barra da
        Tijuca, inscrita no CNPJ/MF sob o nº 01.253.766/0001-40
        (“NBCUNIVERSAL”), de informações sobre a PRODUTORA e de projetos
        audiovisuais originalmente por ela desenvolvidos ("PROJETOS"), através
        do PORTAL GLOBO. GLOBO e NBCUNIVERSAL serão aqui denominadas em conjunto
        como “GRUPO GLOBO”.
      </p>

      <p className="title">A - DISPOSIÇÕES GERAIS PARA USO DO PORTAL GLOBO</p>

      <p className="text">
        1. Para que os PROJETOS sejam aptos para análise do GRUPO GLOBO, a
        PRODUTORA deverá realizar um cadastramento no PORTAL GLOBO, devendo
        observar os seguintes pontos:
      </p>

      <p className="text">
        (i) a PRODUTORA deverá completar o processo prévio do seu cadastramento
        online no PORTAL GLOBO, fornecendo todas as informações ali solicitadas;
      </p>

      <p className="text">
        (ii) a PRODUTORA deverá ser, necessariamente, produtora brasileira
        independentes registrada na ANCINE e nas respectivas Juntas Comerciais,
        com CNPJ regular;
      </p>

      <p className="text">
        (iii) a PRODUTORA, após cadastrar-se e realizar seu login, fornecendo a
        senha e aceitando o presente Termo, poderá apresentar os seus PROJETOS
        fornecendo as informações através do preenchimento dos campos
        disponíveis no PORTAL GLOBO através da Internet e/ou das Tecnologias de
        Plataforma Wireless.
      </p>

      <p className="text">
        1.1. A PRODUTORA deverá ter o máximo de atenção quando de seu
        cadastramento através da Web, ficando certo, desde já, que o GRUPO GLOBO
        não se responsabiliza por erros da PRODUTORA no seu cadastramento, como,
        por exemplo, cadastro duplo na Web, informação incorreta, equivocada ou
        errada de dados, etc.
      </p>

      <p className="text">
        1.2. É terminantemente proibida a realização de mais de um cadastro por
        PRODUTORA, bem como a assunção pela mesma, sem autorização, da
        representação de outra PRODUTORA. Em ambos os casos, o GRUPO GLOBO se
        reserva o direito de bloquear o acesso sem aviso prévio à PRODUTORA.
      </p>

      <p className="text">
        2. Não serão aceitos pela GLOBO o envio de PROJETOS pela PRODUTORA:
      </p>

      <p className="text">
        (i) que desrespeitem a legislação brasileira, de um modo geral e, em
        especial, as leis de direito autoral e/ou de propriedade intelectual;
      </p>

      <p className="text">
        (ii) que contenham imagens, mensagens ou qualquer classe de conteúdo de
        cunho pornográfico, pedófilo, racista, violento, ou ainda ofensivo à
        honra, à vida privada, à imagem, à intimidade pessoal e familiar, ou de
        qualquer maneira ofensivo ou contrário às leis, à ordem, à moral e aos
        bons costumes;
      </p>

      <p className="text">
        (iii) produzidos com o intuito de cometer fraude ou estimular a prática
        de condutas ilícitas ou contrárias à moral;
      </p>

      <p className="text">
        (iv) produzidos sem a devida e prévia obtenção expressa e por escrito de
        todas as autorizações e consentimentos de terceiros que eventualmente
        apareçam no PROJETO e/ou sejam titulares de direitos sobre o mesmo;
      </p>

      <p className="text">
        (v) e que desrespeitem a legislação eleitoral e partidária, cabendo à
        PRODUTORA total e exclusiva responsabilidade pelo conteúdo por esta
        disponibilizado isentando o GRUPO GLOBO de qualquer responsabilidade
        decorrente da violação da legislação citada.
      </p>

      <p className="text">
        3. O GRUPO GLOBO se reserva o direito de alterar quaisquer aspectos do
        PORTAL GLOBO, podendo suspender ou cancelar os mesmos, a seu exclusivo
        critério e a qualquer tempo, independente de aviso prévio à PRODUTORA.
      </p>

      <p className="text">
        4. O GRUPO GLOBO se exime de toda e qualquer responsabilidade pelos
        danos e prejuízos de qualquer natureza que possam ser devidos em razão
        do acesso, interceptação, eliminação, alteração, modificação ou
        manipulação, por terceiros não autorizados, dos arquivos e comunicações
        transmitidos através do PORTAL GLOBO.
      </p>

      <p className="text">
        5. O GRUPO GLOBO se reserva o direito de cancelar e/ou bloquear o acesso
        da PRODUTORA, a qualquer momento e sem prévio aviso, caso seja
        constatado que esta pratique ou venha a praticar algum ato ou mantenha
        ou venha a manter conduta que (i) viole as leis e regulamentos federais,
        estaduais e/ou municipais, (ii) contrarie este Termo, e (iii) viole os
        princípios da moral e dos bons costumes.
      </p>

      <p className="text">
        6. A marcação do check-box ao lado do texto "Concordo com os Termos",
        seguida pelo clique no botão "aceitar" e pela postagem de PROJETOS é
        considerada, para todos os fins de direito, como aceitação pela
        PRODUTORA, via Web, de todos os termos e condições do presente Termo,
        passando o mesmo a regular a relação entre as Partes.
      </p>

      <p className="title">B - DOS PROJETOS APTOS PARA AVALIAÇÃO</p>

      <p className="text">
        1. Os PROJETOS a serem enviados pela PRODUTORA poderão ter qualquer
        temática que seja compatível com a linha editorial do Canal escolhido
        pela PRODUTORA.
      </p>

      <p className="text">
        2. A PRODUTORA poderá enviar quantos PROJETOS desejar, a qualquer
        momento, após o efetuar o cadastro adequadamente no PORTAL GLOBO.
      </p>

      <p className="text">
        3. A PRODUTORA reconhece e concorda que, se o material do PROJETO
        enviado através do PORTAL GLOBO possuir qualquer referência ou incluir
        um link para outros materiais disponíveis em sites, perfis de mídia
        social ou outras plataformas, tais materiais também estarão sujeitos aos
        termos e condições deste instrumento.
      </p>

      <p className="text">
        4. O GRUPO GLOBO irá considerar para a avaliação dos PROJETOS os
        seguintes requisitos: (i) originalidade; (ii) aspectos artísticos; (iii)
        adequação ao público; (iv) qualificação técnica da equipe; e (v)
        capacidade gerencial e desempenho da PRODUTORA.
      </p>

      <p className="text">
        5. O GRUPO GLOBO irá analisar os PROJETOS e poderá, a seu exclusivo
        critério, selecionar ou não PROJETOS para uma posterior negociação de
        direitos com a PRODUTORA, sendo certo que, ainda que ocorra a
        pré-seleção pelo GRUPO GLOBO, não haverá, em nenhuma hipótese, obrigação
        entre as partes de contratarem.
      </p>

      <p className="text">
        6. A PRODUTORA reconhece, desde já, que um material semelhante ou
        idêntico ao do PROJETO ora submetido pode ter sido tornado público ou
        enviado previamente ao GRUPO GLOBO por terceiros, de forma que pode ser
        que o GRUPO GLOBO já detenha o direito de usar tal material a partir de
        outras fontes, sem que seja necessária qualquer negociação de direitos
        com a PRODUTORA. Tais materiais serão aqui denominados como “Materiais
        Prévios”. Também se aplicam ao conceito de Materiais Prévios os
        elementos que sejam derivados de obras já pertencentes ao GRUPO GLOBO
        (tais como remakes, sequels, prequels, etc.). Nestes casos, a PRODUTORA
        reconhece e concorda, desde já, que não terá direito a qualquer
        compensação pelo uso de um Material Prévio pelo GRUPO GLOBO, mesmo que
        tal material seja semelhante ou idêntico ao PROJETO ora submetido pela
        PRODUTORA, renunciando expressamente ao direito de qualquer reclamação
        de qualquer tipo ou natureza neste sentido.
      </p>

      <p className="text">
        7. A PRODUTORA declara e garante, ainda, que: (i) os PROJETOS
        encaminhados ao GRUPO GLOBO estão de acordo com as disposições legais
        aplicáveis; (ii) a utilização de qualquer material protegido por direito
        autoral e direitos da personalidade (como, por exemplo, imagem e voz) na
        concepção dos PROJETOS encontram-se regularizados; (iii) obteve os
        licenciamentos de direitos, permissões e autorizações eventualmente
        necessárias para a execução e/ou exibição dos PROJETOS, inclusive, mas
        não se limitando a, direitos de adaptação de obra literária, formato,
        roteiros, argumentos, de uso de imagem e voz de terceiros, se aplicável;
        e (iv) os PROJETOS não violam direitos de terceiros, incluindo, sem se
        limitar a, direitos autorais (morais e patrimoniais) e direitos da
        personalidade.
      </p>

      <p className="text">
        8. A PRODUTORA declara, desde já, ser a única e exclusiva titular de
        todos os direitos autorais patrimoniais sobre os PROJETOS enviados, não
        tendo o GRUPO GLOBO qualquer responsabilidade ou controle sobre o mesmo.
        Em decorrência da presente declaração, a PRODUTORA, desde já, obriga-se,
        caso venha a ser chamada a responder judicial ou extrajudicialmente pela
        prática de um ato ilícito e/ou ilegal através da utilização do PORTAL
        GLOBO, a isentar o GRUPO GLOBO de quaisquer responsabilidades, bem como
        a requerer a exclusão do mesmo do processo, se for o caso, bem como a
        indenizar o GRUPO GLOBO por toda e qualquer despesa que este venha a
        incorrer, incluindo-se, porém não se limitando, a indenizações, custas
        judiciais e/ou honorários advocatícios.
      </p>

      <p className="text">
        9. Ainda que o GRUPO GLOBO garanta que as informações da PRODUTORA e os
        PROJETOS serão mantidos em sigilo, na hipótese de qualquer terceiro
        considerar que existem fatos ou circunstâncias que constituam ilicitude
        na utilização de conteúdo disponibilizado através do PORTAL GLOBO e, em
        particular, quaisquer PROJETOS que representem práticas criminosas,
        violação de direitos de propriedade intelectual ou outros direitos, este
        deverá enviar uma comunicação, por escrito, à GLOBO, através do endereço
        <a className="link" href="mailto:projetosgsat@globo.com.br">
          {" "}
          projetosgsat@globo.com.br
        </a>
        , contendo os seguintes dados:
      </p>

      <p className="text">
        (i) dados pessoais: nome, endereço, número de telefone e endereço de
        correio eletrônico do reclamante;
      </p>

      <p className="text">
        (ii) especificação da suposta atividade ilícita ocorrida no PORTAL GLOBO
        e, em particular, quando se tratar de suposta violação de direitos
        autorais, indicação precisa e completa dos conteúdos protegidos e
        supostamente infringidos;
      </p>

      <p className="text">
        (iii) fatos ou circunstâncias que revelam o caráter ilícito de tal
        atividade e comprovem a veracidade de informações;
      </p>

      <p className="text">
        (iv) no caso de utilização indevida de criações intelectuais ou qualquer
        tipo de usurpação de direitos, declaração expressa e clara de que a
        utilização dos conteúdos foi realizada sem o consentimento do titular
        dos direitos de propriedade intelectual supostamente infringidos, quando
        aplicável;
      </p>

      <p className="text">
        (v) declaração expressa, clara e sob a responsabilidade do reclamante de
        que a informação proporcionada na notificação é exata e de que a
        utilização do conteúdo dos arquivos indicados constitui uma violação dos
        seus direitos de propriedade intelectual, se aplicável. Estas
        notificações deverão ser enviadas ao GRUPO GLOBO através dos meios
        disponíveis de comunicação.
      </p>

      <p className="text">
        10. A PRODUTORA declara ter lido, estar ciente e de pleno acordo com o
        conteúdo e condições deste Termo, sendo certo que este instrumento se
        sobrepõe a quaisquer acordos, verbais ou escritos, bem como a quaisquer
        entendimentos mantidos anteriormente pelas partes relacionados ao
        PROJETO. Este Termo somente poderá ser alterado, substituído ou
        rescindido por instrumento escrito assinado pelas partes.
      </p>

      <p className="text" ref={privacyPoliciesRef}>
        11. A PRODUTORA declara estar ciente de que a Política de Privacidade da
        GLOBO disponível em{" "}
        <a
          className="link"
          href="https://privacidade.globo.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://privacidade.globo.com
        </a>{" "}
        e a Política de Privacidade da NBCUNIVERSAL disponível em{" "}
        <a
          className="link"
          href="https://www.universalplus.com.br/privacidade"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.universalplus.com.br/privacidade
        </a>{" "}
        descrevem as práticas da GLOBO e da NBCUNIVERSAL, respectivamente, em
        relação a qualquer eventual tratamento de dados pessoais relacionado ao
        envio de PROJETOS através do PORTAL GLOBO. Neste sentido, qualquer
        usuário que utilize o PORTAL GLOBO para o envio de PROJETOS deverá estar
        ciente e de acordo que qualquer eventual tratamento de seus dados
        pessoais será realizado de acordo com tais políticas, sendo certo que a
        política da NBCUNIVERSAL se aplicará apenas para o envio de PROJETOS
        direcionados aos canais Universal TV, Studio Universal e SYFY.
      </p>

      <p className="text">
        12. Fica eleito o Foro Central da Comarca da Cidade do Rio de Janeiro,
        Estado do Rio de Janeiro, para dirimir quaisquer questões oriundas deste
        Termo, desistindo as Partes de qualquer outro, por mais privilegiado que
        seja ou venha a ser. Qualquer disputa que surja em virtude do presente
        Termo será regulada pela Legislação Brasileira.
      </p>
    </div>
  );
};

export default RegulationAndTerms;
