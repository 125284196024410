import gnt from "./images/gnt.png";
import MaisNaTela from "./images/MaisNaTela.png";
import viva from "./images/viva.png";
import glob from "./images/glob.png";
import multiShow from "./images/multiShow.svg";
import bis from "./images/bis.png";
import universal from "./images/universal.png";
import canalOff from "./images/canalOff.png";
import viuHub from "./images/viuHub.png";
import gloobinho from "./images/gloobinho.png";

const Mock = [
  {id: 1, image: gnt, name: "O GNT", title: "Lorem Ipsum", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" },
  {id: 2, image: MaisNaTela, title: "Lorem Ipsum", name: "MAIS NA TELA", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"},
  {id: 3, image: viva, title: "Lorem Ipsum", name: "VIVA", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" },
  {id: 4, image: glob, title: "Lorem Ipsum", name: "O GLOB", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" },
  {id: 5, image: multiShow, title: "Lorem Ipsum", name: "MULTISHOW", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" },
  {id: 6, image: bis, title: "Lorem Ipsum", name: "CANAL BIS", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" },
  {id: 7, image: universal, title: "Lorem Ipsum", name: "UNIVERSAL", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" },
  {id: 8, image: canalOff, title: "Lorem Ipsum", name: "CANAL OFF", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" },
  {id: 9, image: viuHub, title: "Lorem Ipsum", name: "VIU HUB", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" },
  {id: 10, image: gloobinho, title: "Lorem Ipsum", name: "GLOOBINHO", text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" },
]

export default Mock