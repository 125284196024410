import React, { createContext, useReducer, useCallback } from "react";

import appReducer from "./appReducer";

const initialContext = {
  current: {},
  message: null,
  step: [],
  showHeader: true,
  isFull: true,
  showMobileSearch: false,
  showGlassLoading: false,
  showGlassDatepicker: false,
  redirect: false,
  searchTerm: "",
  isCookiesAllowed: false,
};

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialContext);

  const goTo = (url, type) => window.open(url, type);

  const setCurrentStepBox = (step) => {
    setDispatch({ step: step }, "current");
  };

  const setShowGlassLoading = useCallback(
    (bool) => setDispatch(bool, "showGlassLoading"),
    []
  );

  const setShowGlassDatepicker = (bool) =>
    setDispatch(bool, "showGlassDatepicker");

  const sendMessage = (text, type) => {
    let msg = null;
    if (text) msg = { text, type: type ? type : "success" };
    setDispatch(msg, "message");

    window.setTimeout(() => {
      setDispatch(null, "message");
    }, 6500);
  };

  const closeMessage = () => setDispatch(null, "message");

  const convertDatePtBr = (date) =>
    (new Date(date.replace(/Z/g, "")).toLocaleString("pt-br") || "").substring(
      0,
      10
    );

  const convertDateAndTimePtBr = (date) =>
    new Date(date).toLocaleString("pt-br");

  let stringMonth = (month) => {
    let date = {
      "01": "Jan",
      "02": "Fev",
      "03": "Mar",
      "04": "Abr",
      "05": "Mai",
      "06": "Jun",
      "07": "Jul",
      "08": "Ago",
      "09": "Set",
      10: "Out",
      11: "Nov",
      12: "Dez",
    };

    return date[month];
  };

  const formatDate = (date) => {
    let d = date.split("/");
    let day = d[0];
    let month = d[1];
    let year = d[2];
    let finalDate = day + " " + stringMonth(month) + " " + year;
    return finalDate;
  };

  const setRedirectSearch = (bool) => setDispatch(bool, "redirect");

  const setDispatch = (payload, key) => {
    dispatch({ action: "default", key: key, payload: payload });
  };

  const filterErrorMsg = (msg) => {
    return msg?.includes("Captcha inválido:") ? "Captcha inválido" : msg;
  };

  const handleErrorRequest = (error) => {
    let msg = " ";
    if (!!error?.response?.data) {
      error.response.data.Validations.forEach((error) => {
        msg += `<p>${filterErrorMsg(error.Message)} ${error.Property}</p>`;
      });
    }

    return msg.replace(null, "");
  };

  const handleErrorMsg = (error) => {
    let msg = " ";
    if (!!error?.Validations?.length) {
      error.Validations.forEach((error) => {
        msg += `<p>${filterErrorMsg(error.Message)} ${error.Property}</p>`;
      });
    }

    return msg.replace(null, "");
  };

  const sumDate = (date, days) => {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        sendMessage,
        closeMessage,
        setCurrentStepBox,
        setShowGlassLoading,
        setShowGlassDatepicker,
        convertDatePtBr,
        goTo,
        convertDateAndTimePtBr,
        formatDate,
        setRedirectSearch,
        sumDate,
        handleErrorRequest,
        handleErrorMsg,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext as default, AppProvider };
