import React from "react";
import "./styles/Footer.scss";
import Logo from "./logo-rodape.png"

const Footer = () => {

  return (
    <footer id="Footer">
      <img src={Logo} alt="Estúdios globo" />
    </footer>
  );
};

export default Footer;
