import React from 'react'
import './styles/base.scss'
import { AppProvider } from './context/appContext'
import Routes from './routes'
import GlassLoading from "./components/GlassLoading/GlassLoading";


function App () {
  return (
    <AppProvider>
      <GlassLoading />
      <Routes />
    </AppProvider>
  )
}

export default App
