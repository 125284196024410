import React, {useEffect, useState} from 'react';
import Select from "react-select";
import RadioInput from "../../../../components/RadioInput/RadioInput";
import "../Steps/Steps.scss"
import DropdownIndicator from "../../../../components/DropdownIndicator/DropdownIndicator";

let options = [
  {label: "GNT", value: 1},
  {label: "GLOOBINHO", value: 2},
]

let timeProduction = [
  {id: 1, label: "1 a 3 meses", name: "1 a 3 meses", value: "1 a 3 meses"},
  {id: 2, label: "3 a 6 meses", name: "3 a 6 meses", value: "3 a 6 meses"},
  {id: 3, label: "6 meses a 1 ano", name: "6 meses a 1 ano", value: "6 meses a 1 ano"},
  {id: 4, label: "1 ano ou mais", name: "1 ano ou mais", value: "1 ano ou mais"},
]

const Step1 = (props) => {

  const {getData} = props

  const [state, setState] = useState([])

  const handleChange = (value, key) => {
    setState(prevState => {
      return {
        ...prevState,
        [key]: value
      }
    })
  }

  useEffect( () => {
    getData(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <div id="Step1" className="Steps stepsCenter">
        <div className="formItem small" style={{zIndex: 10}}>
          <label>CANAL 1</label>

          <Select
            name="channel"
            className="reactSelect"
            classNamePrefix="reactSelect"
            options={options}
            onChange={(value) => handleChange(value.value, "channel")}
            components={{DropdownIndicator}}
          />
        </div>

        <div className="formItem large" style={{zIndex: 9}}>
          <label>TÍTULO DO PROJETO</label>
          <input onChange={(ev) => handleChange(ev.target.value, "titleProject")} type="text" name="titleProject" value={state?.titleProject} />
        </div>

        <div className="formItem large" style={{zIndex: 9}}>
          <label>PÚBLICO ALVO</label>
          <input type="text" onChange={(ev) => handleChange(ev.target.value, "targetAudience")} name="targetAudience" value={state?.targetAudience} />
        </div>

        <div className="formItem verySmall">
          <label>NÚMERO DO EPISÓDIO</label>
          <input onChange={(ev) => handleChange(ev.target.value, "num_epi")} type="text" name="num_epi" value={state?.num_epi} />
        </div>

        <div className="formItem verySmall">
          <label>NINUTAGEM</label>
          <input type="text" onChange={(ev) => handleChange(ev.target.value, "minute")} value={state?.minute} name="minute"/>
        </div>

        <div className="formItem verySmall">
          <label>PLATAFORMA</label>
          <div className="platform">
            <RadioInput options={[{id: 1, label: "TV", name: "TV", value: 1}, {
              id: 2,
              label: "Site",
              name: "Site",
              value: 2
            }]} handleChange={(value) => handleChange(value, "platform")} value={state?.platform} />
          </div>
        </div>

        <div className="formItem large">
          <label>PRAZO ESTIMADO DE PRODUÇÃO DA OBRA</label>
          <div className="timeProduction">
            <RadioInput value={state?.estimatedTerm} handleChange={(value) => handleChange(value, "estimatedTerm")} options={timeProduction}/>
          </div>
        </div>

        <div className="formItem medium" style={{zIndex: 10}}>
          <label>TIPO DE PROJETO</label>

          <Select
            name="typeProject"
            className="reactSelect"
            classNamePrefix="reactSelect"
            options={options}
            onChange={(value) => handleChange(value.value, "typeProject")}
            components={{DropdownIndicator}}
          />
        </div>

        <div className="formItem large">
          <label>TAGS</label>
          <input type="text" value={state?.tags} onChange={(ev) => handleChange(ev.target.value, "tags")} />
        </div>
    </div>
  );
};

export default Step1;