import React, { useContext } from "react";
import "./styles/Home.scss";

import Title from "../../components/Title/Title";
import Welcome from "../../components/Welcome/Welcome";
import Footer from "../../components/Footer/Footer";

import UserContext from "../../context/userContext";
import { Redirect } from "react-router-dom";
import Login from "../../components/Login/Login";

const Home = (props) => {
  const { match } = props;

  const userContext = useContext(UserContext);

  if (userContext.isLogged) return <Redirect to="/meus-projetos" />;

  return (
    <main id="mainPortal">
      <Title />
      <div className="contentLoginAndWelcome">
        <Login tokenParam={match.params?.token} />
        <Welcome />
      </div>
      <div className="footerPortal">
        <Footer />
      </div>
    </main>
  );
};

export default Home;
