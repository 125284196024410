import React, { useContext, useEffect, useState } from "react";
import ContentBreadCrumb from "../../components/ContentBreadCrumb/ContentBreadCrumb";
import "./styles/RegisterProduction.scss";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import { postService } from "../../services/script-api";
import AppContext from "../../context/appContext";
import Success from "./Steps/Success";
import { useHistory } from "react-router-dom";

const RegisterProduction = () => {
  const [step, setStep] = useState(1);
  const [state, setState] = useState({});
  const [statePhone, setStatePhone] = useState({});
  const [stateError, setStateError] = useState({ email: false });
  const [token, setToken] = useState(null);

  const appContext = useContext(AppContext);
  const history = useHistory();

  const handleChange = (value, key) => {
    setState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };
  const recaptchaRef = React.createRef();

  const onTokenChange = (value) => {
    setToken(value);
    setState((prevState) => {
      return {
        ...prevState,
        captcha: value,
      };
    });
  };

  const handleChangePhone = (key, value) => {
    setStatePhone({
      ...statePhone,
      [key]: value,
    });
  };

  useEffect(() => {
    setState({
      ...state,
      phones: [statePhone],
    });
    // eslint-disable-next-line
  }, [statePhone]);

  const HandleChangeKeyInObj = (keyParent, key, value) => {
    setState({
      ...state,
      [keyParent]: {
        ...state[keyParent],
        [key]: value,
      },
    });
  };

  const handleBlur = (e, key) => {
    setStateError({
      ...stateError,
      [key]: !/\S+@\S+\.\S+/.test(e.target.value),
    });
  };

  const sendRegisterProduction = (ev) => {
    ev.preventDefault();
    // 65.083.642/0001-47
    appContext.setShowGlassLoading(true);

    postService("authentication/register", state)
      .then((res) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Produtora cadastrar com sucesso!");
        setStep(4);
      })
      .catch((error) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(appContext.handleErrorRequest(error), "error");
      });
    recaptchaRef.current.reset();
  };

  return (
    <div id="RegisterProduction">
      <ContentBreadCrumb setStep={setStep} currentStep={step} maxStep={3} />
      {step === 1 && (
        <Step1
          data={state}
          setStep={setStep}
          handleBlur={handleBlur}
          HandleChangeKeyInObj={HandleChangeKeyInObj}
          handleChange={handleChange}
          dataError={stateError}
        />
      )}

      {step === 2 && (
        <Step2
          setStep={setStep}
          data={state}
          HandleChangeKeyInObj={HandleChangeKeyInObj}
          handleChange={handleChange}
        />
      )}

      {step === 3 && (
        <Step3
          data={state}
          setStep={setStep}
          HandleChangeKeyInObj={HandleChangeKeyInObj}
          handleChange={handleChange}
          handleChangePhone={handleChangePhone}
          sendRegisterProduction={sendRegisterProduction}
          token={token}
          onTokenChange={onTokenChange}
          ref={recaptchaRef}
        />
      )}

      {step === 4 && (
        <Success
          title={state.name}
          setStep={setStep}
          redirect={() => history.push("/")}
        />
      )}
    </div>
  );
};
export default RegisterProduction;
