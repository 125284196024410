import React from "react";
import { Link, useHistory } from "react-router-dom";
import SimpleButton from "../../../../components/SimpleButton/SimpleButton";
import MoreIcon from "../../../../components/VectorIcons/MoreIcon";
import RegisterSuccess from "../../../../components/RegisterSuccess/RegisterSuccess";

const Success = (props) => {
  const history = useHistory();
  const data = history.location.state;

  return (
    <div id="Success">
      <RegisterSuccess>
        <div className="container">
          <span className="textSuccessProduction">
            <Link to="/">
              O Projeto <strong>{data.title}</strong>
            </Link>
            foi cadastrado com sucesso!
          </span>

          <p className="subTitleTextSuccessProduction">
            Aguarde enquanto seu projeto é avaliado por nossos especialistas.
          </p>

          <div className="buttons">
            <SimpleButton
              text="Cadastrar outro projeto"
              classButton="buttonBlue extraLarge"
              clickEvent={() =>
                history.push(`/cadastrar/${data.type.toLowerCase()}`)
              }
            >
              <MoreIcon color="#FFF" />
            </SimpleButton>
            <SimpleButton
              text="Ver ficha do projeto"
              classButton="buttonGreen extraLarge"
              clickEvent={() =>
                history.push(
                  `/ficha-projeto/${data.type.toLowerCase()}/${data.id}`
                )
              }
            />
          </div>
        </div>
      </RegisterSuccess>
    </div>
  );
};

export default Success;
