import React, { useEffect, useState } from "react";
import _ from "lodash";
import Select from "react-select";
import RadioInput from "../../../../components/RadioInput/RadioInput";
import Button from "../../../../components/Button/Button";
import SelectArrowIcon from "../../../../components/VectorIcons/SelectArrowIcon";
import { getService } from "../../../../services/script-api";
import AsyncLoading from "../../../../components/AsyncLoading/AsyncLoading";
import SmallLoading from "../../../../components/SmallLoading/SmallLoading";
import ErrorIcon from "../../../../components/VectorIcons/ErrorIcon";
import "./styles/Step.scss";

const DropdownIndicator = ({ innerRef, innerProps, isFocused }) => {
  return (
    <div
      className={`dropdownIndicator ${isFocused ? "isFocused" : ""}`}
      ref={innerRef}
      {...innerProps}
    >
      <SelectArrowIcon />
    </div>
  );
};
const Step1 = (props) => {
  const {
    data,
    setStep,
    handleChange,
    handleChangeThesaurusInfo,
    HandleChangeKeyInObj,
    titleIsValid,
    endPoint,
  } = props;
  const [stateThesaurus, setStateThesaurus] = useState({
    format: {
      loading: true,
      data: [],
    },
    estimated: {
      loading: true,
      data: [],
    },
    platform: {
      loading: true,
      data: [],
    },
  });

  const getThesaurus = (key, id, bool) => {
    getService(
      `project/${endPoint}/thesaurus?Origins=${id}&OnlyFromProductionCompanies=${bool}`
    ).then((res) => {
      setStateThesaurus((prevState) => {
        return {
          ...prevState,
          [key]: {
            loading: false,
            data: res.data,
          },
        };
      });
    });
  };

  useEffect(() => {
    // getChannel()
    getThesaurus("platform", 37, false);
    getThesaurus("estimated", 36, false);
    getThesaurus("format", 22, false);
    getThesaurus("tags", 20, false);
    getThesaurus("Channels", 28, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValid = () =>
    !!data.title &&
    !!data?.complementaryData?.targetAudience &&
    !!data?.tags?.length &&
    !!data.formatId &&
    !!data.chaptersOrEpisodes &&
    !!data?.complementaryData?.minutes &&
    !!data?.ThesaurusInfo?.Platform &&
    !!data?.ThesaurusInfo?.estimatedTerm &&
    titleIsValid;

  return (
    <div id="Step1">
      <h2 className="titleRegisterDramaturgy">DADOS BÁSICOS</h2>
      <div className="container">
        <div
          className="formItem large"
          style={{ zIndex: 20, marginBottom: "20px" }}
        >
          <label>CANAL</label>
          <Select
            name="channel"
            className="reactSelect"
            classNamePrefix="reactSelect"
            options={
              !!stateThesaurus.Channels &&
              stateThesaurus.Channels.data.map((e) => {
                return {
                  ...e,
                  label: e.name,
                  value: e.id,
                };
              })
            }
            onChange={(value) =>
              handleChange(
                [
                  {
                    id: value.id,
                    label: value.name,
                    description: value.description,
                  },
                ],
                "Channels"
              )
            }
            value={
              !!data?.Channels &&
              data?.Channels.map((e) => {
                return {
                  ...e,
                  label: e.name || e.label,
                  value: e.id,
                };
              })
            }
            components={{
              LoadingIndicator: () => <AsyncLoading />,
              DropdownIndicator,
            }}
            placeholder="Selecione um Canal"
          />
        </div>

        <div className="formItem large formTitle">
          <label>TÍTULO</label>
          <input
            type="text"
            name="ancine"
            placeholder="Digite o nome completo"
            onChange={(ev) => handleChange(ev.target.value, "title")}
            value={data.title}
          />
          <span className="footerSpan">
            Utilize letras maiúsculas no ínicio das palavras, exceto
            preposições. <strong>Ver exemplos.</strong>{" "}
          </span>
        </div>
        {!titleIsValid && (
          <div className="errorMsgTitle">
            <ErrorIcon size={12} strokeWidth={4} />
            <p>
              Você já tem um projeto cadastrado com este nome. Não é permitido
              cadastrar o mesmo projeto para outro canal.
            </p>
          </div>
        )}

        <div
          className="formItem large"
          style={{ zIndex: 9, marginTop: "20px" }}
        >
          <label>PÚBLICO ALVO</label>
          <input
            type="text"
            onChange={(e) =>
              HandleChangeKeyInObj(
                "complementaryData",
                "targetAudience",
                e.target.value
              )
            }
            name="targetAudience"
            value={data?.complementaryData?.targetAudience}
          />
        </div>

        <div
          className="formItem large"
          style={{ zIndex: 9, marginBottom: "20px" }}
        >
          <label>TAGS</label>
          <Select
            name="channel"
            className="reactSelect"
            classNamePrefix="reactSelect"
            isMulti
            options={
              !!stateThesaurus.tags &&
              stateThesaurus.tags.data.map((e) => {
                return {
                  ...e,
                  label: e.name,
                  value: e.id,
                };
              })
            }
            isLoading={!stateThesaurus.tags}
            onChange={(value) =>
              handleChange(
                !value
                  ? []
                  : value.map((e) => {
                      return {
                        id: e.id,
                        label: e.name || e.label,
                        description: "",
                      };
                    }),
                "tags"
              )
            }
            components={{
              LoadingIndicator: () => <AsyncLoading />,
              DropdownIndicator,
            }}
            value={
              !!data?.tags &&
              data?.tags.map((e) => {
                return {
                  ...e,
                  label: e.name || e.label,
                  value: e.id,
                };
              })
            }
            placeholder="Selecione Tags"
          />
        </div>

        <div className="formItem adaptableMedium">
          <label>FORMATO</label>
          <Select
            name="format"
            className="reactSelect"
            classNamePrefix="reactSelect"
            options={stateThesaurus.format.data.map((e) => {
              return {
                ...e,
                label: e.name,
                value: e.id,
              };
            })}
            isLoading={stateThesaurus.format.loading}
            value={stateThesaurus.format.data
              .filter((e) => e.id === data.formatId)
              .map((e) => {
                return {
                  ...e,
                  label: e.name,
                  value: e.id,
                };
              })}
            onChange={(value) => handleChange(value.value, "formatId")}
            components={{ DropdownIndicator }}
            placeholder="Selecione um Formato"
          />
        </div>

        <div className="formItem adaptableSmall">
          <label>EPISÓDIOS</label>
          <input
            onChange={(ev) =>
              handleChange(ev.target.value, "chaptersOrEpisodes")
            }
            type="text"
            name="num_epi"
            value={data.chaptersOrEpisodes}
          />
        </div>

        <div className="formItem adaptableSmall">
          <label>MINUTAGEM</label>
          <input
            type="text"
            onChange={(e) =>
              HandleChangeKeyInObj(
                "complementaryData",
                "minutes",
                e.target.value
              )
            }
            name="minute"
            value={data?.complementaryData?.minutes}
          />
        </div>

        <div className="formItem">
          <label>PLATAFORMA</label>
          {stateThesaurus.platform.loading ? (
            <div className="platform">
              <SmallLoading />
            </div>
          ) : (
            <div className="platform">
              <RadioInput
                options={stateThesaurus.platform.data.map((res) => {
                  return {
                    ...res,
                    label: res.name,
                    value: res.id,
                  };
                })}
                handleChange={(value) =>
                  handleChangeThesaurusInfo("Platform", {
                    id: value,
                    description: "",
                  })
                }
                value={data.ThesaurusInfo?.Platform?.id}
              />
            </div>
          )}
        </div>

        <div className="formItem large">
          <label>PRAZO ESTIMADO DE PRODUÇÃO DA OBRA</label>
          {stateThesaurus.estimated.loading ? (
            <div className="timeProductionProject">
              <SmallLoading />
            </div>
          ) : (
            <div className="timeProductionProject">
              <RadioInput
                handleChange={(value) =>
                  handleChangeThesaurusInfo("estimatedTerm", {
                    id: value,
                    description: "",
                  })
                }
                value={data.ThesaurusInfo?.estimatedTerm?.id}
                options={_.orderBy(
                  stateThesaurus.estimated.data,
                  ["id"],
                  ["asc"]
                ).map((res) => {
                  return {
                    ...res,
                    label: res.name,
                    value: res.id,
                  };
                })}
              />
            </div>
          )}
        </div>

        <div className="contentButton">
          <Button
            text="Avançar"
            toValidate={true}
            isValidForm={isValid()}
            classButton="buttonBlue large"
            typeButton="submit"
            dataQA=""
            clickEvent={() => setStep(2)}
          />
        </div>
      </div>
    </div>
  );
};

export default Step1;
