import React from "react";
import { sendEvent } from "../../../../services/GoogleAnalytics/methods";
import "./styles/ListVideos.scss";

const ListVideos = (props) => {
  const { title, platform, url, projectType, password } = props;

  const limitingCharacters = (text) =>
    text.length > 20 ? `${text.slice(0, 20)}...` : text;

  const handleOpenVideo = () => {
    window.open(url, "_blank");
    sendEvent(`Projeto de ${projectType} Externo`, "Visualização de Vídeo");
  };

  return (
    <div className="ListVideos">
      <div className="nameAndPasswordVideos">
        <div onClick={handleOpenVideo}>{limitingCharacters(title)}</div>
      </div>
      <div className="platform">{platform}</div>
      {password && <span className="password">{`Senha: ${password}`}</span>}
    </div>
  );
};

export default ListVideos;
