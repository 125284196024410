import React from 'react';
import Button from "../../../components/Button/Button";
import {Link} from "react-router-dom";

const EmptyProject = () => {
  return (
    <div className="emptyProject">
      <p>Nenhum projeto cadastrado</p>

      <Link to="/cadastrar-projetos">
        <Button
          text="Cadastrar Projeto"
          toValidate={true}
          isValidForm={true}
          classButton="buttonBlue large"
          typeButton="button"
          dataQA="button-prospect-new-save"
        />
      </Link>
    </div>
  );
};

export default EmptyProject;