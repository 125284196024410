import React, { forwardRef, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../../components/Button/Button";
import StateAndCity from "../StateAndCity/StatesAndCity";
import DropdownIndicator from "../../../components/DropdownIndicator/DropdownIndicator";
import CheckBox from "../../../components/CheckBox/CheckBox";
import RegulationAndTerms from "../../../components/RegulationAndTerms/RegulationAndTerms";

const Step3 = (props, ref) => {
  const {
    data,
    HandleChangeKeyInObj,
    handleChangePhone,
    sendRegisterProduction,
    onTokenChange,
    token,
  } = props;

  const [stateAndCity] = useState(StateAndCity);
  const [cities, setCities] = useState([]);

  const [selected, setSelected] = useState("");
  const [isChecked, setIsChecked] = useState("");

  const handleChangeState = (value) => {
    HandleChangeKeyInObj("address", "state", value.value);
    setSelected(value);
  };

  const handleChangeCity = (value) => {
    HandleChangeKeyInObj("address", "city", value.value);
  };

  useEffect(() => {
    setCities(stateAndCity.filter((e) => e.sigla === selected.sigla));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const returnMask = () =>
    data?.phones[0]?.number?.length <= 8 ? "9999-9999" : "99999-9999";

  const isValid = () =>
    !!data?.address?.streetName &&
    !!data?.address?.number &&
    !!data?.address.city &&
    !!data?.address.state &&
    !!data?.phones[0]?.areaCode &&
    data?.phones[0]?.number &&
    data?.phones[0]?.number.length >= 8 &&
    !!isChecked &&
    !!token;

  return (
    <div id="Step3">
      <div className="container contentStepsProduction">
        <h2 className="titleRegisterProduction">Cadastrar seu endereço:</h2>

        <form>
          <div className="formItem large">
            <label>ENDEREÇO</label>
            <input
              type="text"
              name="streetName"
              value={data?.address?.streetName}
              onChange={(e) =>
                HandleChangeKeyInObj("address", "streetName", e.target.value)
              }
            />
          </div>

          <div className="formItem medium">
            <label>NÚMERO</label>
            <input
              type="text"
              name="number"
              value={data?.address?.number}
              onChange={(e) =>
                HandleChangeKeyInObj("address", "number", e.target.value)
              }
            />
          </div>

          <div className="formItem medium">
            <label>COMPLEMENTO</label>
            <input
              type="text"
              name="number"
              value={data?.address?.complement}
              onChange={(e) =>
                HandleChangeKeyInObj("address", "complement", e.target.value)
              }
            />
          </div>

          <div className="formItem medium">
            <label>BAIRRO</label>
            <input
              type="text"
              name="number"
              value={data?.address?.district}
              onChange={(e) =>
                HandleChangeKeyInObj("address", "district", e.target.value)
              }
            />
          </div>

          <div className="formItem medium">
            <label>CEP</label>
            <InputMask
              mask="99999-999"
              value={data?.address?.postalCode}
              maskChar={null}
              onChange={(e) =>
                HandleChangeKeyInObj("address", "postalCode", e.target.value)
              }
            />
          </div>

          <div className="formItem medium zindex10">
            <label>ESTADO</label>
            <Select
              name="_state"
              className="reactSelect"
              classNamePrefix="reactSelect"
              options={stateAndCity.map((e) => {
                return {
                  ...e,
                  label: e.sigla,
                  value: e.sigla,
                };
              })}
              value={{
                label: data?.address?.state,
                value: data?.address?.state,
              }}
              onChange={(value) => handleChangeState(value, "_state")}
              components={{ DropdownIndicator }}
            />
          </div>

          <div className="formItem medium zindex9">
            <label>CIDADE</label>
            <Select
              name="city"
              className="reactSelect"
              classNamePrefix="reactSelect"
              isDisabled={!data?.address?.state}
              options={
                !!cities.length &&
                cities[0]?.cidades.map((e) => {
                  return {
                    label: e,
                    value: e,
                  };
                })
              }
              value={{ label: data?.address?.city, value: data?.address?.city }}
              onChange={(value) => handleChangeCity(value, "city")}
              components={{ DropdownIndicator }}
            />
          </div>

          <div className="formItem adaptableSmall">
            <label>DDD</label>
            <InputMask
              value={data?.phones[0]?.areaCode}
              onChange={(e) => handleChangePhone("areaCode", e.target.value)}
              maxLength={3}
            />
          </div>

          <div className="formItem adaptable">
            <label>TELEFONE</label>
            <InputMask
              mask={returnMask()}
              value={data?.phones[0]?.number}
              onChange={(e) => handleChangePhone("number", e.target.value)}
              maskChar={null}
            />
          </div>
          <div className="container formRecaptcha">
            <ReCAPTCHA
              ref={ref}
              sitekey={window.__.auth.captcha_key}
              onChange={onTokenChange}
            />
          </div>
          <div className="containerRegulation">
            <div
              className="headerRegulation"
              onClick={() => setIsChecked(!isChecked)}
            >
              <CheckBox value={isChecked} isActive={isChecked} /> A produtora
              garante que está de acordo com os Termos de Uso do portal.
            </div>

            <div className="Regulation">
              <RegulationAndTerms bgColor="#f1f2ff" />
            </div>
          </div>

          <div className="contentButton">
            <Button
              text="Avançar"
              toValidate={true}
              isValidForm={isValid()}
              classButton="buttonBlue large"
              typeButton="submit"
              dataQA=""
              clickEvent={sendRegisterProduction}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default forwardRef(Step3);
