import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./views/Home/Home";
import RegisterProduction from "./views/RegisterProduction/RegisterProduction";
import Header from "./components/Header/Header";
import SelectProjectRegistered from "./App/Project/SelectProjectRegistered";
import MyProject from "./App/MyProjects/MyProject";
import ProjectSheetController from "./App/ProjectSheet/ProjectSheetController";
import EditSheetProject from "./App/ProjectSheet/ActionsProjectSheet/EditSheetProject/EditSheetProject";
import { UserProvider } from "./context/userContext";
import Private from "./private";
import Logout from "./components/Auth/Logout";
import { ProjectProvider } from "./context/projetsContext";
import RegisterProjectDramaturgyAndVarieties from "./App/Project/RegisterProject/RegisterProjectDramaturgyAndVarieties";
import About from "./views/About/About";
import Faq from "./views/About/FAQ/FAQ";
import Regulation from "./views/Regulation/Regulation";
import { AttachmentProvider } from "./context/attachmentsContext";
import Success from "./App/Project/RegisterProject/steps/Success";
import CorporateData from "./views/CorporateData/CorporateData";
import GoogleAnalytics from "./services/GoogleAnalytics/GoogleAnalytics";
import CookieApproval from "./components/CookieApproval/CookieApproval";
import Channels from "./App/Channels/Channels";
import { CookiesProvider } from "./context/cookiesContext";
import Helmet from "./components/Helmet/Helmet";

const Routes = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <Header />
        <Helmet />
        <Switch>
          <GoogleAnalytics>
            <Route path="/" exact component={Home} />
            <Route
              path="/cadastrar-produtora"
              exact
              component={RegisterProduction}
            />
            <Route path="/resetar-senha/:token" exact component={Home} />
            <Route path="/sobre-site" exact component={About} />
            <Route path="/faq" exact component={Faq} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/regulamento" exact component={Regulation} />
            <Private>
              <ProjectProvider>
                <AttachmentProvider>
                  <Route
                    path="/cadastrar-projetos"
                    exact
                    component={SelectProjectRegistered}
                  />
                  <Route
                    path="/cadastrar/:slug"
                    exact
                    component={RegisterProjectDramaturgyAndVarieties}
                  />
                  <Route
                    path="/cadastrar-projeto/:project"
                    exact
                    component={EditSheetProject}
                  />
                  <Route path="/meus-projetos" exact component={MyProject} />
                  <Route path="/canais" exact component={Channels} />
                  <Route
                    path="/editar-dados-cadastrais"
                    exact
                    component={CorporateData}
                  />
                  <Route
                    path="/ficha-projeto/:type/:id"
                    exact
                    component={ProjectSheetController}
                  />
                  <Route path="/sucesso" exact component={Success} />
                </AttachmentProvider>
              </ProjectProvider>
            </Private>
          </GoogleAnalytics>
        </Switch>
        <CookiesProvider>
          <CookieApproval />
        </CookiesProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default Routes;
