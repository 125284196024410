import axios from "axios";
import request from "../shared/lib/request";

const configHeaderJSON = { headers: { "content-type": "application/json", "Authorization": localStorage.getItem("accesstoken") } };

export const getService = (path, _params) => {
  const url = `${window.__.endpoints.parceria}/${path}`;
  let params = {};
  if (_params) params = { ...params, ..._params };
  return request.get(url, { params });
};

export const postService = (path, obj) => {
  const url = `${window.__.endpoints.parceria}/${path}`;
  return request.post(url, obj, configHeaderJSON);
};

export const putService = (path, obj) => {
  const url = `${window.__.endpoints.parceria}/${path}`;
  return request.put(url, obj);
};

export const patchService = (path, obj) => {
  const url = `${window.__.endpoints.parceria}/${path}`;
  return request.patch(url, obj);
};

export const deleteService = (path, id) => {
  const url = `${window.__.endpoints.parceria}/${path}/${id}`;
  return request.delete(url);
};

export const deleteServiceQString = (path, obj) => {
  const url = `${window.__.endpoints.parceria}/${path}`;
  return request.delete(url, { params: obj });
};

const cancelToken = axios.CancelToken;
let cancel;
let arrayCancelar = [];

export const putS3File = (url, file, progress) => {
  const config = {
    headers: {
      // "Content-Type": file._meta.type
      "Content-Type": !!file._meta.type
        ? file._meta.type
        : "application/octet-stream",
    },
    onUploadProgress: (event) => progress(event, file),
    cancelToken: new cancelToken(function executor(ref) {
      arrayCancelar.push({ ref, file });
      cancel = ref;
    }),
  };

  return request.put(url, file._meta, config);
};

export const cancelRequest = (file) => {
  arrayCancelar.forEach((item) => {
    if (item.file.originalName === file.originalName) {
      cancel = item.ref;
      cancel("Cancelado");
    }
  });

  // cancel("Cancelado pelo usuário");
};
