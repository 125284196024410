const Fields = [
  {label: "tagline", key: "tagline", maxLetter: 1200},
  {label: "storyline", key: "storyline", maxLetter: 1200},
  {label: "logline", key: "logline", maxLetter: 1200},
  {label: "sinopse geral", key: "summary", maxLetter: 1200},
  {label: "sinopse reduzida", key: "smallSummary", maxLetter: 700},
  {label: "diferencial", key: "differential", maxLetter: 1200},
  {label: "breve currículo roteirista", key: "writerCurriculum", maxLetter: 1200},
  {label: "breve currículo direção", key: "directorCurriculum", maxLetter: 1200},
]

export default Fields