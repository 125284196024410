import React from 'react';
import "./styles/Regulation.scss"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import RegulationAndTerms from "../../components/RegulationAndTerms/RegulationAndTerms";

const Regulation = () => {
  return (
    <div id="Regulation">
      <Breadcrumb>
        <div className="contentBreadCrumbProject">
          <div className="contentLogoAndText">
            <p> Regulamento </p>
          </div>
        </div>
      </Breadcrumb>

      <div className="contentRegulationAndTerms">
        <RegulationAndTerms/>
      </div>

    </div>
  );
};

export default Regulation;