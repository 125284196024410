import React, { useContext } from "react";
import Images from "../../App/Project/Mock";
import "./styles/ListAttachements.scss";
import ScriptBox from "../ScriptBox/ScriptBox";
import AppContext from "../../context/appContext";
import ListAttachmentsBox from "../../App/ProjectSheet/Componentes/ListAttachmentsBox/ListAttachmentsBox";
import AttachmentIcon from "../VectorIcons/AttachmentIcon";
import AttachmentContext from "../../context/attachmentsContext";
import { sendEvent } from "../../services/GoogleAnalytics/methods";

const ListAttachments = (props) => {
  const { files, projectType } = props;

  const appContext = useContext(AppContext);
  const attachmentsContext = useContext(AttachmentContext);

  const handleDownloadFile = ({ fileId, fileName }) => {
    attachmentsContext.getUrlS3(fileId, fileName);
    sendEvent(`Projeto de ${projectType} Externo`, "Download de Anexo");
  };

  if (!files.length) {
    return (
      <div className="cardContent">
        <span>Não há nenhum anexo.</span>
      </div>
    );
  }

  return (
    <div className="ListAttachments">
      {Object.entries(attachmentsContext.groupFiles(files)).map((file, i) => {
        return (
          <div className="images" key={i}>
            <div className="headerFiles">
              <p>{file[0]}</p>
              <div className="date">
                {appContext.formatDate(
                  appContext.convertDatePtBr(file[1][0].receivedAt)
                )}
              </div>
            </div>
            <ul>
              {file[1].map((f, i) => {
                return (
                  <li
                    key={i}
                    onClick={() =>
                      handleDownloadFile({
                        fileId: f.fileId,
                        fileName: f.fileName,
                      })
                    }
                  >
                    {f.fileName}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}

      {appContext.current.step === "showListAttachmentsBox" && (
        <ScriptBox
          open={appContext.current.step === "showListAttachmentsBox"}
          title="Imagens"
          size="large"
          icon={<AttachmentIcon />}
          removePadding={true}
        >
          <ListAttachmentsBox data={Images} />
        </ScriptBox>
      )}
    </div>
  );
};

export default ListAttachments;
