import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
} from "react";
import projectReducer from "./projectsReducer";
import { getService } from "../services/script-api";
import AppContext from "../context/appContext";

const initialContext = {
  list: [],
  loading: true,
  currentType: "variety",
  listProjects: {
    loading: true,
    list: [],
  },
};

const ProjectContext = createContext();

const ProjectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(projectReducer, initialContext);

  const appContext = useContext(AppContext);

  const setShowGlassLoading = appContext.setShowGlassLoading;

  const setLoading = (bool) => {
    dispatch({ payload: bool, key: "loading", action: "loading" });
  };

  const getProjects = useCallback(() => {
    setShowGlassLoading(true);
    getService("project")
      .then((res) => {
        setLoading(false);
        setShowGlassLoading(false);
        dispatch({
          payload: res.data,
          key: "setProjects",
          action: "setProjects",
        });
      })
      .catch((e) => {
        setShowGlassLoading(false);
        setLoading(false);
      });
  }, [setShowGlassLoading]);

  const setLoadingProjectId = (bool) => {
    dispatch({ payload: bool, key: "loading", action: "loadingProject" });
  };

  const getProjectsById = (type, id) => {
    setLoadingProjectId(true);
    getService(`project/${type}/${id}`)
      .then((res) => {
        dispatch({
          payload: res.data,
          key: "setProjectsId",
          action: "setProjectsId",
        });
        setLoadingProjectId(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isYoutube = (url) => {
    if (!!url) {
      let p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return p.test(url);
    }
  };

  const isVimeo = (url) =>
    /^(http:\/\/|https:\/\/)(vimeo\.com)\/([\w/]+)([?].*)?$/.test(url);

  return (
    <ProjectContext.Provider
      value={{
        ...state,
        getProjects,
        getProjectsById,
        isYoutube,
        isVimeo,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectContext as default, ProjectProvider };
