import React from 'react';
import "./styles/Acordion.scss"
import ListFAQ from "./ListFAQ";

const Accordion = (props) => {

  const toggle = (ev) => ev.target.closest(".contentAccordion").classList.toggle("active")

  return (
    <div className="Accordion">
      {ListFAQ.map((e, i) => {
        return (
          <div className="contentAccordion" key={i}>
            <div className="itemArrow"/>
            <p className="title" onClick={toggle}>{e.title}</p>
            <div className="content">
              {e.text}
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default Accordion;