import React from 'react';
import "../styles/RegisterProjectDramaturgyAndVarieties.scss"
import UploadService from "../../../../components/UploadService/UploadService";
import Button from "../../../../components/Button/Button";

const Step3 = (props) => {

  const {setStep, getFiles, getDuplicateFiles, files} = props

  return (
    <div id="Step3">
      <h2 className="titleRegisterDramaturgy">Anexos <span>(Opcional)</span></h2>

      {!!window.__.thesaurus.sinopseArco &&
      <div className="fieldsetUpload">
        <label>Sinopse de episódio</label>
        <UploadService
          thesaurusId={window.__.thesaurus.sinopseArco}
          handleOnComplete={(files) => getFiles(files, "synopsis")}
          getDuplicateFiles={getDuplicateFiles}
          files={files?.synopsis}
        />
      </div>
      }

      {!!window.__.thesaurus.roteiroEpisodio &&
      <div className="fieldsetUpload">
        <label>Roteiro de episódio</label>
        <UploadService
          thesaurusId={window.__.thesaurus.roteiroEpisodio}
          handleOnComplete={(files) => getFiles(files, "episodeScript")}
          getDuplicateFiles={getDuplicateFiles}
          files={files?.episodeScript}
        />
      </div>
      }

      {!!window.__.thesaurus.materialComplementar &&
      <div className="fieldsetUpload">
        <label>Material complementar</label>
        <UploadService
          thesaurusId={window.__.thesaurus.materialComplementar}
          handleOnComplete={(files) => getFiles(files, "complementaryMaterial")}
          getDuplicateFiles={getDuplicateFiles}
          files={files?.complementaryMaterial}
        />
      </div>
      }

      <div className="contentButton">
        <Button
          text="Voltar"
          toValidate={true}
          isValidForm={true}
          classButton="buttonCancel large"
          typeButton="button"
          clickEvent={() => setStep(2)}
        />

        <Button
          text="Avançar"
          toValidate={true}
          isValidForm={true}
          classButton="buttonBlue large"
          typeButton="button"
          clickEvent={() => setStep(4)}
        />
      </div>

    </div>
  );
};

export default Step3;