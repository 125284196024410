import React, { useContext } from "react";
import "./styles/Navigation.scss";
import { NavLink } from "react-router-dom";
import ListNav from "./ListNavigation";
import UserContext from "../../context/userContext";

const Navigation = () => {
  const userContext = useContext(UserContext);

  const returnNavigation = () =>
    !!userContext.isLogged ? ListNav.NavLogged : ListNav.LoggedOut;
  const returnName = (name) => {
    if (!!name) {
      let u = name.split(" ");
      return u[0];
    }
  };

  return (
    <nav id="navNavigationHeader">
      {returnNavigation().map((item, i) => {
        return (
          <NavLink key={i} exact to={item.link} activeClassName="active">
            {!!item.icon && React.createElement(item.icon)}
            <span>{item.name}</span>
          </NavLink>
        );
      })}

      {!!userContext.isLogged && (
        <NavLink exact to="/editar-dados-cadastrais" activeClassName="active">
          <div className="sliceItem">
            {" "}
            {!!userContext.user.me?.name &&
              userContext.user.me?.name.slice(0, 1)}{" "}
          </div>

          {!!userContext.user.me && returnName(userContext.user.me?.name)}
        </NavLink>
      )}

      {!!userContext.isLogged && (
        <div className="logout" onClick={() => userContext.logout()}>
          <span>Sair</span>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
