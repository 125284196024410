import React, { createContext, useContext, useEffect, useReducer } from "react";
import userReducer from "./useReduce";
import { getService, postService } from "../services/script-api";
import { useHistory } from "react-router-dom";
import AppContext from "../context/appContext";

const initialContext = {
  user: {
    loading: true,
    me: null,
  },
  isLogged: false,
};

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialContext);

  const history = useHistory();

  const goTo = (url, type) => window.open(url, type);

  const appContext = useContext(AppContext);

  const setRedirectLogin = (bool) => {
    appContext.setShowGlassLoading(bool);
    history.push("/meus-projetos");
  };

  const logout = () => {
    window.localStorage.removeItem("accesstoken");
    dispatch({ payload: false, key: "isLogged", action: "isLogged" });
    history.push("/");
  };

  const getDataUser = () => {
    getService("productioncompany/me")
      .then((res) => {
        dispatch({ payload: res.data, key: "user", action: "user" });
      })
      .catch((error) => {});
  };

  const refreshUserData = () => {
    getDataUser();
  };

  const makeLogin = (ev, obj) => {
    ev.preventDefault();
    appContext.setShowGlassLoading(true);

    postService("authentication/login", obj)
      .then((res) => {
        window.localStorage.setItem("accesstoken", res.data);
        dispatch({ payload: true, key: "isLogged", action: "isLogged" });
        setRedirectLogin(false);
      })
      .catch((error) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(appContext.handleErrorRequest(error), "error");
      });
  };

  const sendResetMyPassword = (e, obj) => {
    e.preventDefault();
    postService("authentication/request-recovery-password", obj)
      .then((res) => {
        appContext.setCurrentStepBox("sucessResetPassword");
      })
      .catch((e) => {
        appContext.sendMessage("Erro ao resetar a senha!", "error");
        appContext.setCurrentStepBox("closed");
      });
  };

  const changeMyPassword = (e, obj) => {
    e.preventDefault();

    const { confirmPassword, ...restObj } = obj;

    if (obj?.confirmPassword === obj?.newPassword) {
      postService("authentication/process-recovery-password", restObj)
        .then((res) => {
          if (!res?.Validations?.length) {
            appContext.sendMessage("Senha alterada com sucesso!");
            appContext.setCurrentStepBox("closed");
          } else {
            const errorMsg = appContext.handleErrorMsg(res);
            appContext.sendMessage(errorMsg, "error");
          }
        })
        .catch((e) => {
          const errorMsg = appContext.handleErrorRequest(e);
          appContext.sendMessage(errorMsg, "error");
        });
    } else {
      appContext.sendMessage("Senhas incorretas", "error");
    }
  };

  const makeLogout = () => {
    window.localStorage.removeItem("accesstoken");
    dispatch({ payload: false, key: "isLogged", action: "isLogged" });
  };

  useEffect(() => {
    if (!!window.localStorage.getItem("accesstoken")) {
      dispatch({ payload: true, key: "isLogged", action: "isLogged" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.localStorage.getItem("accesstoken")]);

  useEffect(() => {
    if (state.isLogged) {
      getDataUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLogged]);

  return (
    <UserContext.Provider
      value={{
        ...state,
        goTo,
        makeLogin,
        makeLogout,
        logout,
        sendResetMyPassword,
        changeMyPassword,
        refreshUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext as default, UserProvider };
