import React from "react";
import "./styles/Welcome.scss";
import { Link } from "react-router-dom";
import HelpDesk from "../HelpDesk/HelpDesk";

const Welcome = () => {
  return (
    <div id="WelCome">
      <p>
        <strong>Bem-vindo.</strong>
      </p>

      <p>
        Neste espaço as produtoras podem submeter para avaliação quantos
        projetos quiserem.
      </p>

      <p>
        <Link to="/cadastrar-produtora">Cadastre-se</Link> para inscrever seu
        projeto.
      </p>

      <HelpDesk />
    </div>
  );
};

export default Welcome;
