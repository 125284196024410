import React, { useEffect, useState } from "react";
import CheckBox from "../../../../components/CheckBox/CheckBox";
import InfoIcon from "../../../../components/VectorIcons/InfoIcon";
import RegulationAndTerms from "../../../../components/RegulationAndTerms/RegulationAndTerms";
import Button from "../../../../components/Button/Button";
import CurrencyInput from "react-currency-input";

const Step5 = (props) => {
  const { data, HandleChangeKeyInObj, sendProject, setStep } = props;

  const [isChecked, setIsChecked] = useState(false);
  const [stateOthers, setStateOthers] = useState({});

  const setKeyAndValue = (ev, maskedValue, value, keyParent, key) =>
    HandleChangeKeyInObj(keyParent, key, value);

  const setOthers = (d, key, value) => {
    setStateOthers({
      ...stateOthers,
      [key]: value,
    });
  };

  useEffect(() => {
    HandleChangeKeyInObj("CostAndFinancialInformation", "Others", stateOthers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateOthers]);

  const isValid = () =>
    !!data?.CostAndFinancialInformation?.CostPerEpisode &&
    !!data?.CostAndFinancialInformation?.TotalCost &&
    isChecked;

  return (
    <div id="Step5">
      <h2 className="titleRegisterDramaturgy">
        Estimativas de custo e financiamentos{" "}
      </h2>

      <form>
        <div className="contentFormItemCheckBox">
          <h3>A obra já possui outras fontes de financiamento?</h3>
          {data?.Channels?.[0]?.label !== "Globoplay" && (
            <>
              <div className="contentCheckBoxAndInputValue">
                <div className="checkBoxItem">Editais Públicos</div>
                <div className="inputValue">
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    value={data?.CostAndFinancialInformation?.PublicNoteCost}
                    placeholder="R$"
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    onChangeEvent={(ev, maskedValue, value) =>
                      setKeyAndValue(
                        ev,
                        maskedValue,
                        value,
                        "CostAndFinancialInformation",
                        "PublicNoteCost"
                      )
                    }
                  />
                </div>
              </div>
              <div className="contentCheckBoxAndInputValue">
                <div className="checkBoxItem">Recursos próprios</div>

                <div className="inputValue">
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    value={data?.CostAndFinancialInformation?.OwnResourcesCost}
                    placeholder="R$"
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    onChangeEvent={(ev, maskedValue, value) =>
                      setKeyAndValue(
                        ev,
                        maskedValue,
                        value,
                        "CostAndFinancialInformation",
                        "OwnResourcesCost"
                      )
                    }
                  />
                </div>
              </div>
              <div className="contentCheckBoxAndInputValue">
                <div className="checkBoxItem">Leis de incentivo à cultura</div>

                <div className="inputValue">
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    value={
                      data?.CostAndFinancialInformation?.CultureIncentiveLawCost
                    }
                    placeholder="R$"
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    onChangeEvent={(ev, maskedValue, value) =>
                      setKeyAndValue(
                        ev,
                        maskedValue,
                        value,
                        "CostAndFinancialInformation",
                        "CultureIncentiveLawCost"
                      )
                    }
                  />
                </div>
              </div>
              <div className="contentCheckBoxAndInputValue">
                <div className="checkBoxItem">Coprodutores Nacionais</div>

                <div className="inputValue">
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    value={
                      data?.CostAndFinancialInformation?.NationalCoProducerCost
                    }
                    placeholder="R$"
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    onChangeEvent={(ev, maskedValue, value) =>
                      setKeyAndValue(
                        ev,
                        maskedValue,
                        value,
                        "CostAndFinancialInformation",
                        "NationalCoProducerCost"
                      )
                    }
                  />
                </div>
              </div>

              <div className="contentCheckBoxAndInputValue">
                <div className="checkBoxItem">Coprodutores Internacionais</div>

                <div className="inputValue">
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    value={
                      data?.CostAndFinancialInformation
                        ?.InternationalCoProducerCost
                    }
                    placeholder="R$"
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    onChangeEvent={(ev, maskedValue, value) =>
                      setKeyAndValue(
                        ev,
                        maskedValue,
                        value,
                        "CostAndFinancialInformation",
                        "InternationalCoProducerCost",
                        value
                      )
                    }
                  />
                </div>
              </div>
            </>
          )}

          <div className="contentCheckBoxAndInputValue">
            <div className="checkBoxItem">Outros</div>

            <div className="inputValue">
              <input
                type="text"
                placeholder="Especifique"
                value={data?.CostAndFinancialInformation?.Others?.key}
                onChange={(value) => setOthers(data, "key", value.target.value)}
              />
              <CurrencyInput
                id="input-example"
                name="input-name"
                value={data?.CostAndFinancialInformation?.Others?.value}
                placeholder="R$"
                decimalSeparator=","
                thousandSeparator="."
                onChangeEvent={(ev, maskedValue, value) =>
                  setOthers(data, "value", value)
                }
                disabled={!data?.CostAndFinancialInformation?.Others?.key}
              />
            </div>
          </div>

          <div className="estimates">
            <div className="medium">
              <label>
                proposta de estimativa de custo p/ episódio{" "}
                <InfoIcon color="#5c5b97" />{" "}
              </label>

              <CurrencyInput
                id="input-example"
                name="input-name"
                value={data?.CostAndFinancialInformation?.CostPerEpisode}
                placeholder="R$"
                prefix="R$"
                decimalSeparator=","
                thousandSeparator="."
                onChangeEvent={(ev, maskedValue, value) =>
                  setKeyAndValue(
                    ev,
                    maskedValue,
                    value,
                    "CostAndFinancialInformation",
                    "CostPerEpisode"
                  )
                }
              />
            </div>

            <div className="medium">
              <label>
                proposta de estimativa de custo total{" "}
                <InfoIcon color="#5c5b97" />
              </label>

              <CurrencyInput
                id="input-example"
                name="input-name"
                value={data?.CostAndFinancialInformation?.TotalCost}
                placeholder="R$"
                prefix="R$"
                decimalSeparator=","
                thousandSeparator="."
                onChangeEvent={(ev, maskedValue, value) =>
                  setKeyAndValue(
                    ev,
                    maskedValue,
                    value,
                    "CostAndFinancialInformation",
                    "TotalCost"
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="containerRegulation">
          <div
            className="headerRegulation"
            onClick={() => setIsChecked(!isChecked)}
          >
            <CheckBox value={isChecked} isActive={isChecked} /> A produtora
            garante que está de acordo com os Termos de Uso do portal.
          </div>

          <div className="Regulation">
            <RegulationAndTerms bgColor="#f1f2ff" />
          </div>
        </div>

        <div className="contentButton">
          <Button
            text="Voltar"
            toValidate={true}
            isValidForm={true}
            classButton="buttonCancel large"
            typeButton="button"
            clickEvent={() => setStep(4)}
          />

          <Button
            text="Finalizar cadastro"
            toValidate={true}
            isValidForm={isValid()}
            classButton="buttonGreen large"
            typeButton="button"
            clickEvent={() => sendProject()}
          />
        </div>
      </form>
    </div>
  );
};

export default Step5;
