import axios from "axios";
import qs from "qs";
import {
  getIdToken,
  /*getRefreshToken,
  handleLogin,
  logout,*/
} from "../lib/login-store";
// import { refreshAuth } from "../../services/auth-api";

// let isRefreshing = false;
// let subscribers = [];

const client = axios.create({
  paramsSerializer: (params) => {
    let p = qs.stringify(params, {
      arrayFormat: "bracket",
      encode: false,
      allowDots: true,
    });
    return p;
  },
});

/*function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed(token) {
  subscribers.map((cb) => cb(token));
}*/

client.interceptors.request.use(
  function (config) {
    if (config.url.startsWith(window.__.endpoints.base)) {
      if (!config._retry) {
        config.headers["Authorization"] = getIdToken();
        config.headers["x-api-key"] = window.__.auth.api_key;
      }
    } else {
      delete config.headers.common["Authorization"];
      delete config.headers.common["x-api-key"];
      delete config.headers.common["x-p"];
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    // console.error("Request Failed:", error.config);

    let responseStatus = error.response ? error.response.status : 0;
    // const originalRequest = error.config;

    if (responseStatus === 403 || responseStatus === 401) {
      //Force user re-login when refresh token is invalid.
      const isLogin = error.response.config.url.includes(
        "portal/authentication/login"
      );
      if (window.location.pathname !== "/?expired-token" && !isLogin) {
        window.localStorage.removeItem("accesstoken");
        window.location = "/?expired-token";
      }
    } else if (responseStatus >= 400 /*&& responseStatus <= 500*/) {
      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        // console.error("Status:", error.response.status);
        // console.error("Data:", error.response.data);
        // console.error("Headers:", error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        // console.error("Error Message:", error.message);
      }
    }
    // else {
    //   console.error("Ocorreu um erro ao processar sua solicitação");
    // }

    return Promise.reject(error);
  }
);

export default client;
