import React from "react";
import { Link } from "react-router-dom";
import "./styles/About.scss";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const About = () => {
  return (
    <div id="About">
      <Breadcrumb>
        <div className="contentBreadCrumbProject">
          <div className="contentLogoAndText">
            <p> Sobre o site </p>
          </div>
        </div>
      </Breadcrumb>
      <div className="container containerAbout">
        <p>Prezado produtor,</p>

        <p>
          Este site tem como objetivo aumentar a eficácia de nossa interlocução
          e otimizar o tempo de trabalho de todos nós. A inscrição possibilita a
          melhor forma de apresentar seus projetos à Equipe Globosat. Cada etapa
          do formulário é muito importante pois trata dos aspectos fundamentais
          para avalicão do canal. Desta forma, recomendamos que a produtora
          preencha todos os campos de cadastro{" "}
          <Link to="/faq">listados no FAQ</Link>
        </p>

        <p>
          O prazo de avaliação será de 90 dias após a finalização da inscrição.
          Os critérios de análise são: originalidade, aspectos artísticos e
          adequação ao público, qualificação técnica da equipe e capacidade
          gerencial e desempenho da produtora.
        </p>

        <p>
          Preservar as ideias, as registrando e catalogando, para que os
          direitos de todos sejam respeitados é nosso compromisso com o mercado
          independente.
        </p>

        <p>
          A Globosat poderá solicitar, a qualquer momento, documentos e
          informações adicionais que considere necessários para a avaliação dos
          projetos.
        </p>

        <p>
          Não serão aceitos projetos que não tenham características de absoluta
          originalidade.
        </p>
      </div>
    </div>
  );
};

export default About;
