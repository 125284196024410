import React from "react";
import "./styles/Textarea.scss";
import TextareaAutosize from "react-textarea-autosize";

const Textarea = (props) => {
  const {
    _class,
    isError,
    label,
    name,
    maxRows,
    minRows = 4,
    handleChange,
    value,
    placeholder,
    icon = ""
  } = props;

  return (
    <fieldset className={`textarea ${_class || ""} ${isError ? "error" : ""}`}>
     {label && <label htmlFor={name}>{label} <span>{icon && icon}</span></label>}

      <div className="textareaContainer">
        <TextareaAutosize
          onChange={handleChange}
          maxRows={maxRows}
          minRows={minRows}
          value={value}
          placeholder={placeholder}
        />
      </div>
    </fieldset>
  );
};

export default Textarea;
