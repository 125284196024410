const setLoading = (action, state) => {
  return {
    ...state,
    loading: action.payload
  }
}

const setLoadingProject = (action, state) => {
  return {
    ...state,
    listProjects: {
      ...state.listProjects,
      loading: action.payload
    }
  }
}

const setProjectsId = (action, state) => {
  return {
    ...state,
    listProjects: {
      ...state.listProjects,
      list: action.payload
    }
  }
}

const setProjects = (action, state) => {
  return {
    ...state,
    list: action.payload
  }
}

export default (state, action) => {
  let _state = {
    loading: () => setLoading(action, state),
    loadingProject: () => setLoadingProject(action, state),
    setProjects: () => setProjects(action, state),
    setProjectsId: () => setProjectsId(action, state)
  }

  return _state[action.action]()
}
