import React from 'react';
import "./style/counterLetters.scss"

const CounterLetters = (props) => {
  const {min = 0, max = 1200, numberCurrentLetters = 0, isError} = props

  return (
    <div className="CounterLetters">
      {!isError ? (
        <div>{min} de {max - numberCurrentLetters}</div>
      ) : (
        <div className="errorCount">Máximo caracter atingido</div>
      )}
    </div>
  );
};

export default CounterLetters;