import React, { useEffect, useState, useCallback } from "react";
import "./styles/TableListFile.scss";
import TableLoading from "../../components/TableLoading/TableLoading";
import ListCount from "../../components/ListCount/ListCount";
import BlockList from "./BlockList/BlockList";
import _ from "lodash";

const TableListFiles = (props) => {
  const {
    handleEditFile,
    data,
    loading,
    handleConfirmationDeleteFile,
    havePermission,
    condition,
  } = props;

  const [stateListFiles, setStateListFiles] = useState([]);

  const compileFiles = useCallback((filesArray) => {
    const itemsWithType = filesArray.map((item) => ({
      ...item,
      type: item.attachmentType.name,
    }));
    const groupedByType = _.groupBy(itemsWithType, "type");
    const groupedByTypeOrdered = Object.fromEntries(
      Object.entries(groupedByType).sort((a, b) => {
        return a[0].toLowerCase() > b[0].toLowerCase() ? -1 : 1;
      })
    );
    const groupedByTypeEntries = Object.entries(groupedByTypeOrdered);
    const groupedAndOrdered = groupedByTypeEntries.map((item) =>
      item[1].sort((a, b) => {
        return a.fileName.toLowerCase() > b.fileName.toLowerCase() ? 1 : -1;
      })
    );
    setStateListFiles(groupedAndOrdered.flat(1));
  }, []);

  useEffect(() => {
    compileFiles(data);
  }, [compileFiles, data]);

  const returnColor = (count) => (!!count ? "#7f808e" : "#ec1045");

  return (
    <section id="TableListFiles">
      <div className="filesListCounter">
        <ListCount
          total={stateListFiles.length}
          text={`${
            stateListFiles.length > 1
              ? "Anexos encontrados"
              : "Anexo encontrado"
          }`}
        />
      </div>

      <div className="tableContainer">
        {loading ? (
          <TableLoading />
        ) : (
          stateListFiles.length > 0 && (
            <div className="tableList">
              <ul className="itemsList">
                <li className="headerList">
                  <span className="headerType">Tipo</span>
                  <span className="headerName">Arquivo</span>
                  <span className="headerReceivedAt">Recebido em</span>
                  <span className="headerActions" />
                </li>

                {stateListFiles.map((list, groupIndex) => {
                  return (
                    <li className="groupList" key={groupIndex}>
                      {
                        <BlockList
                          item={list}
                          list={list}
                          condition={condition}
                          havePermission={havePermission}
                          handleEditFile={handleEditFile}
                          handleConfirmationDeleteFile={
                            handleConfirmationDeleteFile
                          }
                          returnColor={returnColor}
                          _class="mainItem"
                        />
                      }
                    </li>
                  );
                })}
              </ul>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default TableListFiles;
