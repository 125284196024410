import React, {useContext, useEffect, useState} from 'react';
import "./styles/ListItem.scss"
import TagStatus from "../../../components/TagStatus/TagStatus";
import ReorderButton from "../../../components/ReorderButton/ReorderButton";
import {Link} from "react-router-dom";
import AppContext from "../../../context/appContext";
import _ from "lodash"
import TypeIconReturner from "../../../components/TypeIconReturner/TypeIconReturner";

const List = (props) => {

  const {index, data} = props

  const appContext = useContext(AppContext)

  return (
    <ul className="items" key={index}>
      <li className="title">
        <TypeIconReturner type={data.type.toLowerCase()} responsiveIcon={true}/>
        <Link to={`/ficha-projeto/${data.type.toLowerCase()}/${data.id}`}>{data.title}</Link>
      </li>
      <li className="channel">{data.channel}</li>
      <li className="date">{appContext.convertDateAndTimePtBr(data.date).substring(0, 10)}</li>
      <li className="status">
        <TagStatus name={data.status.description} id={data.status.id}/>
      </li>
    </ul>
  )
}

const ListItem = (props) => {

  const {data} = props

  const [state, setState] = useState([])
  const [stateOrder, setStateOrder] = useState({
    date: 0,
    title: 0
  })

  useEffect(() => {
    setState(data)
    // eslint-disable-line no-undef
  }, [data])

  const reorder = (typeOrder) => {
    let projects = _.orderBy(state, [typeOrder], [stateOrder[typeOrder] === 0 ? "desc" : "asc"])
    setStateOrder({
      ...stateOrder,
      [typeOrder]: stateOrder[typeOrder] === 0 ? 1 : 0
    })
    setState(projects)
  }

  return (
    <div className="ListItem">
      <ul className="HeaderListItem">
        <li className="title active" onClick={() => reorder("title")}>
          <ReorderButton
            text="Título"
            color="#3898CD"
            direction={stateOrder.title}
          />
        </li>
        <li className="channel">CANAL</li>
        <li className="date active" onClick={() => reorder("date")}>
          <ReorderButton
            text="Data"
            color="#3898CD"
            direction={stateOrder.date}
          />
        </li>
        <li className="status">STATUS</li>
      </ul>

      {!!state.length && state.map((item, i) => <List index={i} key={i} data={item}/>)}

    </div>
  );
};

export default ListItem;