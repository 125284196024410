import React, { useEffect, useState } from "react";
import "./style/ResetSucessBox.scss";

const ResetSucessBox = (props) => {
  const [stateBox, setStateBox] = useState({ open: false });

  const { children, size, removePadding, allowOverflow, marginBottom } = props;

  useEffect(() => {
    setTimeout(() => {
      setStateBox({ open: props.open });
    }, 100);
  }, [props.open]);

  return (
    <div
      className={`ScriptBox ${stateBox.open ? "active" : ""} ${
        !!size ? size : ""
      }`}
    >
      <div className="bgScriptBox" />
      <div className="windowScriptBox">
        <div
          className={`content ${removePadding ? "removePadding" : ""} ${
            allowOverflow ? "allowOverflow" : ""
          } ${marginBottom ? "marginBottom" : ""}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ResetSucessBox;
