import React, { useState} from 'react';
import Textarea from "../../../../components/Textarea/Textarea";
import ListFields from "./ListFields";
import CounterLetters from "../../../../components/CountLetters/CounterLetters";
import Button from "../../../../components/Button/Button";
import "./styles/Step.scss"
import InfoIcon from "../../../../components/VectorIcons/InfoIcon";

const Step2 = (props) => {

  const {data, setStep, HandleChangeKeyInObj, type} = props
  const [error, setError] = useState({})

  const handleChangeComplementary = (event, key, max) => {
    setError({
      ...error,
      [key]: max < event.target.value.length
    })
    if(max < event.target.value.length) return false
    HandleChangeKeyInObj('complementaryData', key, event.target.value)
  }

  const returnValue = (key, value) => !!key ? key[value] : ""

  const isError = () => Object.entries(error).every( (e) => !e[1])
  const isValid = () => ListFields.filter(e => e.label !== returnType[type]).every(e => data.complementaryData ? data?.complementaryData[e.key] : "")

  const returnType = {
    "variedades": "storyline",
    "dramaturgia": "logline"
  }

  return (
    <div id="Step2">
      <h2 className="titleRegisterDramaturgy">Dados Complementares</h2>

      {ListFields.filter(e => e.label !== returnType[type]).map((item, index) => {
        return (
          <div className={`contentTextArea ${error[item.key] ? "error" : ""}`} key={index}>
            <Textarea
              maxRows={12}
              minRows={0}
              label={item.label}
              placeholder=""
              icon={<InfoIcon color="#5c5b97"/>}
              handleChange={(event) => handleChangeComplementary(event, item.key, item.maxLetter)}
              value={returnValue(data.complementaryData, item.key)}
            />

            {error[item.key] ? (
              <span className="errorLabel">Número de caractéres excedido. Máximo permitido {item.maxLetter}</span>
            ) : (
              <CounterLetters
                isError={item.maxLetter <= data[item.key]?.length}
                max={item.maxLetter}
                numberCurrentLetters={returnValue(data.complementaryData, item.key)?.length}
              />
            )}

          </div>
        )
      })}

      <div className="contentButton">
        <Button
          text="Voltar"
          toValidate={true}
          isValidForm={true}
          classButton="buttonCancel large"
          typeButton="button"
          clickEvent={() => setStep(1)}
        />

        <Button
          text="Avançar"
          toValidate={true}
          isValidForm={isValid() && isError()}
          classButton="buttonBlue large"
          typeButton="button"
          clickEvent={() => setStep(3)}
        />
      </div>
    </div>
  );
};

export default Step2;