import React, {useContext} from 'react';
import CloseIcon from "../../../../components/VectorIcons/CloseIcon";
import Button from "../../../../components/Button/Button";
import ProjectContext from "../../../../context/projetsContext";

const Step4 = (props) => {

  const {setStep, data, addMoreVideo, handleChangeVideos, removeVideo} = props

  const projectContext = useContext(ProjectContext)

  return (
    <div id="Step4">
      <h2 className="titleRegisterDramaturgy">Videos <span>(Opcional)</span></h2>
      <p className="subTitleRegisterProduction">
        Caso queira enviar vídeos como referência ou pilotos, use o espaço abaixo:
      </p>
      {data.map((item, i) => {
        return (
          <div className="contentRegisterVideos" key={i}>
            {data.length > 1 &&
            <div className="removeItem" onClick={() => removeVideo(i)}>
              <CloseIcon color="#514a81"/>
            </div>
            }
            <div className="formItemVideo">
              <label>Título do video</label>
              <input type="text" name="ancine"
                     onChange={(ev) => handleChangeVideos(ev.target.value, "title", i)}
                     value={item.title}
              />
            </div>

            <div className="formItemVideo">
              <label>URL do vimeo ou youtube</label>
              <input type="text" name="url"
                     autoComplete="off"
                     onChange={(ev) => handleChangeVideos(ev.target.value, "url", i)}
                     value={item.url}
              />
            </div>

            {projectContext.isVimeo(item.url) &&
            <div className="formItemVideo">
              <label>Senha do vimeo</label>
              <input type="text" name="password"
                     placeholder=""
                     autoComplete="off"
                     disabled={!projectContext.isVimeo(item.url)}
                     onChange={(ev) => handleChangeVideos(ev.target.value, "password", i)}
                     value={item.password}
              />
            </div>
            }
          </div>
        )
      })}

      <div
        className="dashedBox"
        onClick={() => addMoreVideo()}
      >
        <span>+ Incluir Outro Vídeo</span>
      </div>

      <div className="contentButton">
        <Button
          text="Voltar"
          toValidate={true}
          isValidForm={true}
          classButton="buttonCancel large"
          typeButton="button"
          clickEvent={() => setStep(3)}
        />

        <Button
          text="Avançar"
          toValidate={true}
          isValidForm={true}
          classButton="buttonBlue large"
          typeButton="button"
          clickEvent={() => setStep(5)}
        />
      </div>
    </div>
  );
};

export default Step4;