import React, { useState, useContext } from "react";
import "./styles/EditCorporateData.scss";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import Fieldset from "../../../components/Fieldset/Fieldset";
import { putService } from "../../../services/script-api";
import AppContext from "../../../context/appContext";
import UserContext from "../../../context/userContext";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";

const EditCorporateData = (props) => {
  const { data } = props;

  const [corporateData, setCorporateData] = useState({
    id: data.id,
    ancine: data.ancine,
    corporateName: data.corporateName,
    name: data.name,
    cnpj: data.cnpj,
    stateRegistration: data.stateRegistration,
    ownerName: data?.owner?.name,
    ownerEmail: data?.owner?.email,
    phones: [
      {
        areaCode: data.phones[0]?.areaCode,
        number: `(${data.phones[0]?.areaCode}) ${data.phones[0]?.number}`,
      },
    ],
  });

  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const handleSetCorporateData = ({ key, value }) => {
    setCorporateData({ ...corporateData, [key]: value });
  };

  const isValidForm = () =>
    !!corporateData.ancine &&
    !!corporateData.corporateName &&
    !!corporateData.name &&
    corporateData.phones[0].number.length >= returnPhoneLength();

  const returnPhoneLength = () => {
    const isCellPhone = corporateData.phones[0].number[6] === "9";
    if (isCellPhone) return 16;
    return 15;
  };

  const handlePutEdit = () => {
    const putData = {
      id: corporateData.id,
      ancine: corporateData.ancine,
      corporateName: corporateData.corporateName,
      name: corporateData.name,
      stateRegistration: corporateData.stateRegistration,
      phones: [
        {
          areaCode: corporateData.phones[0].number.slice(1, 3),
          number: corporateData.phones[0].number.slice(5),
        },
      ],
    };

    appContext.setShowGlassLoading(true);

    putService("productioncompany", putData)
      .then(() => {
        appContext.sendMessage("Dados editados com sucesso!");
        sendEvent(`Dados da Produtora`, "Edição dos dados da Produtora");
        appContext.setCurrentStepBox("closed");
        userContext.refreshUserData();
        appContext.setShowGlassLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        appContext.sendMessage("Erro ao editar dados", "error");
        appContext.setCurrentStepBox("closed");
        appContext.setShowGlassLoading(false);
      });
  };

  return (
    <div className="editCorporateData">
      <form>
        <div className="formItem medium">
          <Fieldset
            label="NÚMERO ANCINE"
            type="text"
            name="ancine"
            value={corporateData.ancine}
            handleChange={(ev) =>
              handleSetCorporateData({ key: "ancine", value: ev.target.value })
            }
          />
        </div>

        <div className="formItem large">
          <Fieldset
            label="RAZÃO SOCIAL"
            type="text"
            name="corporateName"
            value={corporateData.corporateName}
            handleChange={(ev) =>
              handleSetCorporateData({
                key: "corporateName",
                value: ev.target.value,
              })
            }
          />
        </div>

        <div className="formItem large">
          <Fieldset
            label="NOME FANTASIA"
            type="text"
            name="name"
            value={corporateData.name}
            handleChange={(ev) =>
              handleSetCorporateData({
                key: "name",
                value: ev.target.value,
              })
            }
          />
        </div>

        <div className="formItemDouble">
          <div className="formItem medium">
            <Fieldset
              label="CNPJ"
              type="text"
              name="cnpj"
              value={corporateData.cnpj}
              disabled={true}
              isMasked={true}
              mask="99.999.999/9999-99"
              maskChar={null}
            />
          </div>

          <div className="formItem medium">
            <Fieldset
              label="INSCRIÇÃO ESTADUAL"
              type="text"
              name="stateRegistration"
              value={corporateData.stateRegistration}
              isMasked={true}
              mask="999.999.999.999"
              maskChar={null}
              handleChange={(ev) =>
                handleSetCorporateData({
                  key: "stateRegistration",
                  value: ev.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="formItem large">
          <Fieldset
            label="NOME DO RESPONSÁVEL"
            type="text"
            name="nome-responsavel"
            value={corporateData.ownerName}
            disabled={true}
          />
        </div>

        <div className="formItem large">
          <Fieldset
            label="EMAIL"
            type="text"
            name="email"
            value={corporateData.ownerEmail}
            disabled={true}
          />
        </div>

        <div className="formItem">
          <Fieldset
            label="TELEFONE"
            type="text"
            name="phones"
            value={corporateData.phones[0].number}
            isMasked={true}
            mask={
              corporateData.phones[0].number.length >= 7 &&
              corporateData.phones[0].number[6] === "9"
                ? "(99) 99999-99999"
                : "(99) 99999-9999"
            }
            maskChar={null}
            handleChange={(ev) =>
              handleSetCorporateData({
                key: "phones",
                value: [
                  {
                    id: corporateData.phones[0].id,
                    areaCode: corporateData.phones[0].areaCode,
                    number: ev.target.value,
                  },
                ],
              })
            }
          />
        </div>

        <BoxFooter>
          <SimpleButton
            text="Salvar"
            classButton="buttonGreen large"
            toValidate={true}
            isValidForm={isValidForm()}
            clickEvent={handlePutEdit}
          />
        </BoxFooter>
      </form>
    </div>
  );
};

export default EditCorporateData;
