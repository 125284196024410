import React, { useState, useEffect, useCallback, useContext } from "react";
import "./styles/EditAddress.scss";
import Select from "react-select";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import Fieldset from "../../../components/Fieldset/Fieldset";
import StatesAndCity from "../../RegisterProduction/StateAndCity/StatesAndCity";
import DropdownIndicator from "../../../components/DropdownIndicator/DropdownIndicator";
import AppContext from "../../../context/appContext";
import UserContext from "../../../context/userContext";
import { putService } from "../../../services/script-api";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";

const EditAddress = (props) => {
  const { data } = props;
  const { city, complement, district, number, postalCode, state, streetName } =
    data.address;

  const [addressData, setAddressData] = useState({
    id: data.id,
    city: { label: city, value: city },
    complement: complement,
    district: district,
    number: number,
    postalCode: postalCode,
    state: { label: state, value: state },
    streetName: streetName,
  });

  const [cityOptions, setCityOptions] = useState([]);

  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const handleChange = ({ key, value }) => {
    setAddressData((prevState) => ({ ...prevState, [key]: value }));

    if (key === "state")
      setAddressData((prevState) => ({ ...prevState, city: null }));
  };

  const initializeStateValue = useCallback(() => {
    const stateValue = StatesAndCity.find((e) => e.sigla === state);
    setAddressData({ ...addressData, state: stateValue });
  }, [addressData, state]);

  const isValidForm = () =>
    !!addressData.city &&
    !!addressData.district &&
    !!addressData.number &&
    !!addressData.state &&
    !!addressData.streetName;

  const handlePutEdit = () => {
    const putData = {
      productionCompanyId: addressData.id,
      city: addressData.city.value || addressData.city,
      complement: addressData.complement,
      district: addressData.district,
      number: addressData.number,
      state: addressData.state.sigla || addressData.state,
      streetName: addressData.streetName,
    };

    appContext.setShowGlassLoading(true);

    putService("productioncompany/address", putData)
      .then(() => {
        appContext.sendMessage("Dados editados com sucesso!");
        sendEvent(`Dados da Produtora`, "Edição de Endereço da Produtora");
        appContext.setCurrentStepBox("closed");
        userContext.refreshUserData();
        appContext.setShowGlassLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        appContext.sendMessage("Erro ao editar dados", "error");
        appContext.setCurrentStepBox("closed");
        appContext.setShowGlassLoading(false);
      });
  };

  useEffect(() => {
    if (!addressData.state.sigla) initializeStateValue();

    const selectedCityOption = StatesAndCity.filter(
      ({ sigla }) => sigla === addressData.state.sigla
    );

    setCityOptions(selectedCityOption);
  }, [addressData, initializeStateValue]);

  return (
    <div className="editAddress">
      <form>
        <div className="formItem large">
          <Fieldset
            label="ENDEREÇO"
            type="text"
            name="ENDEREÇO"
            value={addressData.streetName}
            handleChange={(ev) =>
              handleChange({ key: "streetName", value: ev.target.value })
            }
          />
        </div>

        <div className="formItem medium">
          <Fieldset
            label="NÚMERO"
            type="text"
            name="NÚMERO"
            value={addressData.number}
            handleChange={(ev) =>
              handleChange({ key: "number", value: ev.target.value })
            }
          />
        </div>

        <div className="formItem large">
          <Fieldset
            label="COMPLEMENTO"
            type="text"
            name="COMPLEMENTO"
            value={addressData.complement}
            handleChange={(ev) =>
              handleChange({ key: "complement", value: ev.target.value })
            }
          />
        </div>

        <div className="formItemDouble">
          <div className="formItem">
            <Fieldset
              label="BAIRRO"
              type="text"
              name="BAIRRO"
              value={addressData.district}
              handleChange={(ev) =>
                handleChange({ key: "district", value: ev.target.value })
              }
            />
          </div>

          <div className="formItem medium">
            <label>CIDADE</label>
            <Select
              className="reactSelect"
              classNamePrefix="reactSelect"
              isDisabled={!addressData.state}
              options={
                !!cityOptions.length &&
                cityOptions[0]?.cidades.map((e) => ({
                  label: e,
                  value: e,
                }))
              }
              value={{
                label: addressData?.city?.label,
                value: addressData?.city,
              }}
              onChange={(value) => handleChange({ key: "city", value: value })}
              components={{ DropdownIndicator }}
            />
          </div>
        </div>

        <div className="formItem medium">
          <label>ESTADO</label>
          <Select
            className="reactSelect"
            classNamePrefix="reactSelect"
            options={StatesAndCity.map((e) => ({
              ...e,
              label: e.sigla,
              value: e.sigla,
            }))}
            value={{
              label: addressData?.state?.sigla,
              value: addressData?.state,
            }}
            onChange={(value) => handleChange({ key: "state", value: value })}
            components={{ DropdownIndicator }}
          />
        </div>

        <BoxFooter>
          <SimpleButton
            text="Salvar"
            classButton="buttonGreen large"
            clickEvent={handlePutEdit}
            toValidate={true}
            isValidForm={isValidForm()}
          />
        </BoxFooter>
      </form>
    </div>
  );
};

export default EditAddress;
