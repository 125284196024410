import React, { useContext } from "react";
import ScriptBox from "../../../components/ScriptBox/ScriptBox";
import EditIcon from "../../../components/VectorIcons/EditIcon";
import AppContext from "../../../context/appContext";
import ChangePassword from "../ChangePassword/ChangePassword";
import EditAddress from "../EditAddress/EditAddress";
import EditCorporateData from "../EditCorporateData/EditCorporateData";
import EditPresentation from "../EditPresentation/EditPresentation";

const EditModals = (props) => {
  const { data } = props;

  const appContext = useContext(AppContext);

  return (
    <>
      {appContext.current.step === "editCorporateData" && (
        <ScriptBox
          open={appContext.current.step === "editCorporateData"}
          title="Dados da Produtora"
          size="medium"
          icon={<EditIcon color="#ffffff" />}
          removePadding={true}
        >
          <EditCorporateData data={data} />
        </ScriptBox>
      )}

      {appContext.current.step === "editAddress" && (
        <ScriptBox
          open={appContext.current.step === "editAddress"}
          title="Endereço"
          size="medium"
          icon={<EditIcon color="#ffffff" />}
          removePadding={true}
          marginBottom={true}
        >
          <EditAddress data={data} />
        </ScriptBox>
      )}

      {appContext.current.step === "editPresentation" && (
        <ScriptBox
          open={appContext.current.step === "editPresentation"}
          title="Apresentação"
          size="medium"
          icon={<EditIcon color="#ffffff" />}
          removePadding={true}
        >
          <EditPresentation data={data} />
        </ScriptBox>
      )}

      {appContext.current.step === "changePassword" && (
        <ScriptBox
          open={appContext.current.step === "changePassword"}
          title="Alterar sua senha"
          size="medium"
          icon={<EditIcon color="#ffffff" />}
          removePadding={true}
        >
          <ChangePassword />
        </ScriptBox>
      )}
    </>
  );
};

export default EditModals;
