import React, {useContext} from 'react';
import "./styles/Header.scss"
import Navigation from "../Navigation/Navigation";
import ScriptIcon from "../VectorIcons/ScriptIcon";
import {Link} from "react-router-dom";
import UserContext from "../../context/userContext"
import AppContext from "../../context/appContext"
import Alerts from "../Alerts/Alerts";

const Header = () => {

  const userContext = useContext(UserContext)
  const appContext = useContext(AppContext)

  return (
    <div id="HeaderPortal">
      <div className="containerHeader">
        {/*<div className="closedMenu">x</div>*/}
        <h1>
          <Link to={userContext.isLogged ? "/meus-projetos" : "/"}>
            <ScriptIcon width="108" /> PRODUTORAS
          </Link>
        </h1>
        <Navigation />
      </div>

      {!!appContext.message && (
        <div className="containerFlex">
          <Alerts
            type={appContext.message.type}
            message={appContext.message.text}
          />
        </div>
      )}
    </div>
  );
};

export default Header;