import React, { useState } from "react";
import "./styles/ListChannels.scss";
import mockChannels from "./content";

const ListChannels = () => {
  const [modalConfig, setModalConfig] = useState({
    logo: null,
    text: "",
    name: "",
    open: false,
  });

  return (
    <div id="listChannels">
      {modalConfig?.open && (
        <div className="modalChannel">
          <div
            className="closeButton"
            onClick={() =>
              setModalConfig({
                logo: "",
                text: "",
                name: "",
                open: false,
              })
            }
          >
            X
          </div>
          <img src={modalConfig.logo} alt={modalConfig.name} />
          {modalConfig.text}
        </div>
      )}
      <ul className="channelList">
        {mockChannels.map((channel, index) => (
          <li
            key={index}
            className="channelItem"
            onClick={() =>
              setModalConfig({
                logo: channel.logo,
                text: channel.text,
                name: channel.name,
                open: true,
              })
            }
          >
            <img src={channel.logo} alt={channel.name} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListChannels;
