import React, { useState, useEffect, useContext } from "react";
import UserContext from "./context/userContext";

export default (props) => {
  const { children } = props;

  const accessToken = window.localStorage.accesstoken;

  const userContext = useContext(UserContext);
  const useIsLogged = userContext.isLogged;

  const isLogged = accessToken && useIsLogged;

  const [state, setState] = useState(isLogged);

  useEffect(() => {
    setState(isLogged);
  }, [isLogged]);

  return !!state ? children : <div className="private" />;
};
