import React, {
  createContext,
  useReducer,
  useEffect,
  useCallback,
} from "react";

import cookiesReducer from "./appReducer";

const initialContext = {
  isCookiesAllowed: false,
};

const CookiesContext = createContext();

const CookiesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cookiesReducer, initialContext);

  const setIsCookiesAllowed = (bool) => {
    window.localStorage.setItem("isCookiesAllowed", bool);
    dispatch({ action: "default", key: "isCookiesAllowed", payload: bool });
  };

  const checkForIsCookiesAllowed = useCallback(() => {
    return window.localStorage.getItem("isCookiesAllowed");
  }, []);

  useEffect(() => {
    const isAllowed = checkForIsCookiesAllowed();
    if (!isAllowed) {
      setIsCookiesAllowed(false);
      window.localStorage.removeItem("isCookiesAllowed");
    }
    if (isAllowed) setIsCookiesAllowed(true);
  }, [state.isCookiesAllowed, checkForIsCookiesAllowed]);

  return (
    <CookiesContext.Provider
      value={{
        ...state,
        setIsCookiesAllowed,
      }}
    >
      {children}
    </CookiesContext.Provider>
  );
};

export { CookiesContext as default, CookiesProvider };
