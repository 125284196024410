const makeLogin = (action, state) => {
  return {
    ...state,
    isLogged: action.payload
  }
}

const setUserData = (action, state) => {
  return {
    ...state,
    user: {
      ...state.user,
      me: action.payload
    }
  }
}

export default (state, action) => {
  let _state = {
    isLogged: () => makeLogin(action, state),
    user: () => setUserData(action, state)
  }

  return !!_state[action.action] ? _state[action.action]() : ""
}
