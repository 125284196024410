import React from "react";
import "./style/Fieldset.scss";
import ErrorIcon from "../VectorIcons/ErrorIcon";
import InputMask from "react-input-mask";

const Fieldset = (props) => {
  const {
    type,
    name,
    label,
    value,
    placeholder,
    handleChange,
    autoComplete,
    isError,
    msgError,
    handleBlur,
    _class,
    disabled = false,
    darkBackgroundColor,
    isAlert,
    maxLength,
    isMasked,
    mask,
    maskChar,
  } = props;

  return (
    <fieldset
      className={`fieldset ${_class || ""} ${isError ? "error" : ""} ${
        isAlert ? "alert" : ""
      }`}
    >
      {label && <label htmlFor={name}>{label}</label>}

      <div
        className={`inputContainer ${
          darkBackgroundColor ? "darkBackgroundColor" : ""
        }`}
      >
        {!isMasked && (
          <input
            autoComplete={autoComplete}
            type={type}
            id={name}
            className={`${isError && "error"}`}
            value={value}
            name={name}
            placeholder={placeholder}
            onBlur={handleBlur ? (e) => handleBlur(e) : () => {}}
            onChange={handleChange ? (ev) => handleChange(ev) : () => {}}
            disabled={disabled}
            maxLength={maxLength}
          />
        )}

        {isMasked && (
          <InputMask
            mask={mask}
            value={value}
            maskChar={maskChar}
            className={`${isError && "error"}`}
            onChange={handleChange ? (ev) => handleChange(ev) : () => {}}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}

        {isError && (
          <div className="errorIcon">
            <ErrorIcon />
          </div>
        )}
      </div>

      {isError && (
        <>
          <span className="errorMessage">{msgError}</span>
        </>
      )}
    </fieldset>
  );
};

export default Fieldset;
