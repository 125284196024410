import React from "react";
import "./styles/TypeIconReturner.scss";
import Masks from "../VectorIcons/Masks";
import Microphone from "../VectorIcons/Microphone";
import ResearchesIcon from "../VectorIcons/ResearchesIcon";
import LiteraryWorkIcon from "../VectorIcons/LiteraryWorkIcon";

const TypeIconReturner = (props) => {
  const { type, showBackgroundColor, responsiveIcon } = props;

  const returnBackgroundColor = (iconType) => {
    const types = {
      dramaturgia: "#ec1045",
      dramaturgy: "#ec1045",
      variedade: "#2d76d4",
      variedades: "#2d76d4",
      variety: "#2d76d4",
      pesquisa: "#009c69",
      "obra-literaria": "#DF9A37",
      "obra literária": "#DF9A37",
    };

    return types[iconType];
  };

  const returnIconType = (iconType) => {
    const types = {
      dramaturgia: <Masks color={showBackgroundColor ? "#fff" : "#ec1045"} />,
      dramaturgy: <Masks color={showBackgroundColor ? "#fff" : "#ec1045"} />,
      variedade: (
        <Microphone color={showBackgroundColor ? "#fff" : "#2d76d4"} />
      ),
      variedades: (
        <Microphone color={showBackgroundColor ? "#fff" : "#2d76d4"} />
      ),
      variety: <Microphone color={showBackgroundColor ? "#fff" : "#2d76d4"} />,
      pesquisa: (
        <ResearchesIcon color={showBackgroundColor ? "#fff" : "#009c69"} />
      ),
      "obra-literaria": (
        <LiteraryWorkIcon color={showBackgroundColor ? "#fff" : "#DF9A37"} />
      ),
      "obra literária": (
        <LiteraryWorkIcon color={showBackgroundColor ? "#fff" : "#DF9A37"} />
      ),
    };

    return types[iconType];
  };

  const backgroundColor =
    !!showBackgroundColor && returnBackgroundColor(type.toLowerCase());

  return (
    <div
      className={`typeIconReturner ${responsiveIcon ? "responsiveIcon" : ""}`}
      style={{ backgroundColor: backgroundColor }}
    >
      {returnIconType(type.toLowerCase())}
    </div>
  );
};

export default TypeIconReturner;
