import React, {useState} from 'react';
import "./styles/EditSheetProject.scss"
import "../Steps/Steps.scss"
import Step1 from "../Steps/Step1";

const EditSheetProject = () => {

  const [currentStep, setCurrentStep] = useState(1)

  const getData = (data) => {

  }

  return (
    <div id="EditSheetProject">
      <div className="Steps">
        <form>
          <Step1 getData={getData} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </form>
      </div>
    </div>
  );
};

export default EditSheetProject;