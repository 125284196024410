import React from "react";
import "./styles/RegisterOptions.scss";
import RoundCloseButton from "../../components/RoundCloseButton/RoundCloseButton";
import RegisterCard from "../../components/RegisterCard/RegisterCard";
import Masks from "../../components/VectorIcons/Masks";
import Microphone from "../../components/VectorIcons/Microphone";

const SelectProjectRegistered = (props) => {
  return (
    <div id="RegisterOptions">
      <div
        className="closeButton"
        onClick={() => props.history.push("/meus-projetos")}
      >
        <RoundCloseButton color="#36355A" />
      </div>
      <span className="optionsTitle">O que você quer cadastrar?</span>
      <span className="optionsSubTitle">Escolha uma opção</span>

      <div className="cardsContainer">
        <RegisterCard
          option={`Projeto de Ficção`}
          iconCollor="rgb(236, 16, 69)"
          redirectTo="cadastrar/dramaturgia"
          className="active"
        >
          <Masks />
        </RegisterCard>
        <RegisterCard
          option={`Projeto de Não Ficção`}
          iconCollor="rgb(45, 118, 212)"
          redirectTo="cadastrar/variedades"
          className="active"
        >
          <Microphone />
        </RegisterCard>
      </div>
    </div>
  );
};

export default SelectProjectRegistered;
