import React, { useContext, useEffect, useState } from "react";
import ContentBreadCrumb from "../../../components/ContentBreadCrumb/ContentBreadCrumb";
import Step1 from "./steps/Step1";
import "./styles/RegisterProjectDramaturgyAndVarieties.scss";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import AppContext from "../../../context/appContext";
import Step5 from "./steps/Step5";
import { postService } from "../../../services/script-api";
import { useHistory } from "react-router-dom";
import Success from "./steps/Success";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";

const RegisterProjectDramaturgyAndVarieties = (props) => {
  let initialState = { title: "", url: "", password: "", type: 1 };

  const { match } = props;
  const history = useHistory();

  const returnType = {
    dramaturgia: "dramaturgy",
    variedades: "variety",
  };

  const [step, setStep] = useState(1);
  const [state, setState] = useState({
    ThesaurusInfo: {},
  });
  const [stateVideos, setStateVideos] = useState([initialState]);
  const [stateFiles, setStateFiles] = useState([]);
  const [currentId, setCurrentId] = useState("");
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [stateThesaurus] = useState({
    episodeScript: 19054,
    synopsis: 1178,
    complementaryMaterial: 1208,
  });

  const initialDate = new Date();

  const appContext = useContext(AppContext);

  const getDuplicateFiles = (files) => {
    let filesName = "";
    files.map((e) => (filesName += `<p>${e.name} ja foi selecionado!</p>`));
    appContext.sendMessage(filesName, "error");
  };

  const compileFiles = (files) => {
    let a = [];
    const initialDate = new Date();

    if (!!files) {
      files.map((file) => {
        return a.push({
          ...file,
          fileId: file.fileId,
          attachmentTypeId: file.attachmentTypeId,
          fileName: file.originalName,
          version: file.version,
          receivedAt: new Date(
            initialDate.getTime() + initialDate.getTimezoneOffset() * 60000
          ).toISOString(),
          blockNumber: file.blockNumber,
          chapterNumber: file.chapterNumber,
        });
      });
    }
    return a;
  };

  const getFiles = (files, key) => {
    setStateFiles((prevState) => {
      return {
        ...prevState,
        [key]: compileFiles(files),
      };
    });
  };

  const handleChange = (value, key) => {
    setState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });

    if (key === "title") {
      titleVerifyDuplicated(value);
    }
  };

  const titleVerifyDuplicated = (title) => {
    postService("project/check-project-name", { Title: title })
      .then((res) => {
        setTitleIsValid(res.data.isValid);
        res.data.isValid
          ? document.querySelector(".formTitle").classList.remove("MsgError")
          : document.querySelector(".formTitle").classList.add("MsgError");
      })
      .catch((error) => {
        appContext.sendMessage(appContext.handleErrorRequest(error), "error");
      });
  };

  const HandleChangeKeyInObj = (keyParent, key, value) => {
    setState({
      ...state,
      [keyParent]: {
        ...state[keyParent],
        [key]: value,
      },
    });
  };

  const addMoreVideo = () => {
    const newState = [...stateVideos];
    newState.push(initialState);
    setStateVideos(newState);
  };

  const handleChangeVideos = (value, key, index) => {
    const newState = [...stateVideos];
    newState[index][key] = value;
    setStateVideos(newState);
  };

  const removeVideo = (index) => {
    const newState = [...stateVideos];
    setStateVideos(newState.filter((e, i) => i !== index));
  };

  const handleChangeThesaurusInfo = (key, value) => {
    const newState = { ...state };
    newState.ThesaurusInfo[key] = value;
    setState(newState);
  };

  const sendProject = () => {
    appContext.setShowGlassLoading(true);
    let attachments = [];
    attachments.push(
      ...stateFiles.episodeScript,
      ...stateFiles.synopsis,
      ...stateFiles.complementaryMaterial
    );

    let ThesaurusInfo = [];

    ThesaurusInfo.push(
      state.ThesaurusInfo?.Platform,
      state.ThesaurusInfo?.estimatedTerm
    );

    let obj = {
      receivedAt: new Date(
        initialDate.getTime() + initialDate.getTimezoneOffset() * 60000
      ).toISOString(),
      origins: [2],
      associations: {
        projects: [],
      },
      ...state,
      ThesaurusInfo,
      attachments,
      Videos: filterVideos(),
    };

    postService(`project/${returnType[match.params.slug]}`, obj)
      .then((res) => {
        appContext.setShowGlassLoading(false);
        setCurrentId(res.data);
        appContext.sendMessage("Projeto cadastrado com sucesso!");
        history.push("/sucesso", {
          title: state.title,
          id: res.data,
          type: match.params.slug,
        });
        sendEvent(
          `Projeto de ${match.params.slug} Externo`,
          "Criação de Projeto"
        );
      })
      .catch((error) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(appContext.handleErrorRequest(error), "error");
      });
  };

  const clearSteps = () => {
    setState({
      ThesaurusInfo: {},
    });
    // setStateFiles([])
  };

  useEffect(() => {
    return clearSteps();
  }, []);

  const filterVideos = () => stateVideos.filter((e) => !!e.title && !!e.url);

  const labelsByType = (type) => {
    const types = {
      dramaturgia: "Ficção",
      variedades: "Não Ficção",
    };
    return types[type];
  };

  return (
    <div id="Dramaturgy">
      <ContentBreadCrumb maxStep={5} setStep={setStep} currentStep={step}>
        Cadastro: Projeto <span> de </span> {labelsByType(match.params.slug)}
      </ContentBreadCrumb>

      <div className="container">
        <div className="containerSteps">
          {step === 1 && (
            <Step1
              data={state}
              setStep={setStep}
              handleChange={handleChange}
              HandleChangeKeyInObj={HandleChangeKeyInObj}
              endPoint={returnType[match.params.slug]}
              handleChangeThesaurusInfo={handleChangeThesaurusInfo}
              titleIsValid={titleIsValid}
            />
          )}

          {step === 2 && (
            <Step2
              data={state}
              setStep={setStep}
              type={match.params.slug}
              handleChange={handleChange}
              HandleChangeKeyInObj={HandleChangeKeyInObj}
            />
          )}

          {step === 3 && (
            <Step3
              data={state}
              setStep={setStep}
              thesaurus={stateThesaurus}
              files={stateFiles}
              getFiles={getFiles}
              getDuplicateFiles={getDuplicateFiles}
              handleChange={handleChange}
              HandleChangeKeyInObj={HandleChangeKeyInObj}
            />
          )}

          {step === 4 && (
            <Step4
              setStep={setStep}
              addMoreVideo={addMoreVideo}
              handleChangeVideos={handleChangeVideos}
              removeVideo={removeVideo}
              data={stateVideos}
              HandleChangeKeyInObj={HandleChangeKeyInObj}
            />
          )}

          {step === 5 && (
            <Step5
              data={state}
              setStep={setStep}
              sendProject={sendProject}
              HandleChangeKeyInObj={HandleChangeKeyInObj}
            />
          )}

          {step === 6 && (
            <Success
              title={state.title}
              setStep={setStep}
              clearStep={setState}
              type={match.params.slug}
              redirect={() =>
                history.push(
                  `/ficha-projeto/${match.params.slug.toLowerCase()}/${currentId}`
                )
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterProjectDramaturgyAndVarieties;
