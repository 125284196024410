import React from "react";
import InputMask from "react-input-mask";
import Button from "../../../components/Button/Button";

const Step1 = (props) => {
  const {
    setStep,
    dataError,
    data,
    handleChange,
    handleBlur,
    HandleChangeKeyInObj,
  } = props;

  const isValid = () =>
    !!data?.user?.name &&
    !!data?.user?.email &&
    !!data.ancine &&
    !!data.corporateName &&
    !!data.name &&
    data?.cnpj &&
    !dataError.email;

  return (
    <div id="Step1">
      <div className="container contentStepsProduction">
        <h2 className="titleRegisterProduction">
          Ao cadastrar-se a produtora poderá se logar no sistema e inscrever
          seus projetos.
        </h2>

        <form>
          <div className="formItem large">
            <label>NÚMERO ANCINE</label>
            <input
              type="text"
              name="ancine"
              value={data.ancine}
              onChange={(ev) => handleChange(ev.target.value, "ancine")}
            />
          </div>

          <div className="formItem large">
            <label>RAZÃO SOCIAL</label>
            <input
              type="text"
              name="corporateName"
              value={data.corporateName}
              onChange={(ev) => handleChange(ev.target.value, "corporateName")}
            />
          </div>

          <div className="formItem large">
            <label>NOME FANTASIA</label>
            <input
              type="text"
              name="name"
              value={data.name}
              onChange={(ev) => handleChange(ev.target.value, "name")}
            />
          </div>

          <div className="formItem medium">
            <label>CNPJ</label>
            <InputMask
              mask="99.999.999/9999-99"
              value={data?.cnpj}
              maskChar={null}
              onChange={(ev) => handleChange(ev.target.value, "cnpj")}
            />
          </div>

          <div className="formItem medium">
            <label>INSCRIÇÃO ESTADUAL</label>
            <InputMask
              mask="999.999.999.999"
              value={data?.stateRegistration}
              maskChar={null}
              onChange={(ev) =>
                handleChange(ev.target.value, "stateRegistration")
              }
            />
          </div>

          <div className="formItem large">
            <label>NOME DO RESPONSÁVEL</label>
            <input
              type="text"
              name="nome-responsavel"
              value={data?.user?.name}
              onChange={(e) =>
                HandleChangeKeyInObj("user", "name", e.target.value)
              }
            />
          </div>

          <div className="formItem large">
            <label>EMAIL</label>
            <input
              type="text"
              autoComplete="off"
              name="email"
              value={data?.user?.email}
              onKeyUp={(e) => handleBlur(e, "email")}
              onChange={(e) =>
                HandleChangeKeyInObj("user", "email", e.target.value)
              }
            />
            {!!dataError.email && (
              <div className="errorLabel">Campo e-mail inválido!</div>
            )}
          </div>

          <div className="contentButton">
            <Button
              text="Avançar"
              toValidate={true}
              isValidForm={isValid()}
              classButton="buttonBlue large"
              typeButton="button"
              dataQA=""
              clickEvent={() => setStep(2)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Step1;
