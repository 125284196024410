import React, {useContext, useState} from 'react';
import ProjectSheet from "./ProjectSheet";
// import EditSheetProject from "./ActionsProjectSheet/EditSheetProject/EditSheetProject";
import AppContext from "../../context/appContext"
import ScriptBox from "../../components/ScriptBox/ScriptBox";
import EditIcon from "../../components/VectorIcons/EditIcon";
import Step1 from "./ActionsProjectSheet/Steps/Step1";
import SimpleButton from "../../components/SimpleButton/SimpleButton";
import BoxFooter from "../../components/BoxFooter/BoxFooter";

const ProjectSheetController = (props) => {

  const appContext = useContext(AppContext)
  const [currentStep, setCurrentStep] = useState(1)

  const getData = (data) => {

  }

  return (
    <div id="ProjectSheetController">
      <ProjectSheet params={props.match.params} />
      {appContext.current.step === 'EditBasicProjectSheet' &&
      <ScriptBox
        open={appContext.current.step === 'EditBasicProjectSheet'}
        title="Edit Project"
        size="medium"
        icon={<EditIcon color="#ffffff"/>}
        removePadding={true}
      >
        <form>
          <Step1 getData={getData} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
          <BoxFooter>
            <SimpleButton
              text="Salvar"
              classButton="buttonGreen large"
              dataQA=""
              clickEvent={() => {
              }}
            />
          </BoxFooter>
        </form>

      </ScriptBox>

      }
    </div>
  );
}
;

export default ProjectSheetController;