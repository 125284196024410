import React from "react";
import "./styles/CheckBox.scss";
import CheckIcon from "../VectorIcons/CheckIcon";

const CheckBox = (props) => {
  const { label, event = () => {}, isActive, index, value } = props;

  const checkItem = (ev, i) => {
    let checkBox = document.getElementsByClassName("check")[0];
    let target = ev.target.closest(".checked");

    if (checkBox) {
      if (!target) {
        checkBox.classList.remove("checked");
      }
    }

    ev.target.closest(".checkBox").classList.toggle("checked");
    event(!value);
  };

  return (
    <div
      className={`checkBox ${isActive ? "checked" : ""}`}
      onClick={(e) => checkItem(e, index)}
    >
      <div className="check">
        <div className="icon">
          <CheckIcon color="#FFF" />
        </div>
      </div>

      <span className="label">{label}</span>
    </div>
  );
};

export default CheckBox;
