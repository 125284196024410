import React from "react";

const RegisterIcon = (props) => {
  const { color = "#17B4E1", size = 30 } = props;

  return (
    <svg
      fill="none"
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1 9C1 4.58203 4.58203 1 9 1H15C19.418 1 23 4.58203 23 9V15C23 19.418 19.418 23 15 23H9C4.58203 23 1 19.418 1 15V9ZM12.6777 6.26465C12.876 6.44727 13 6.70898 13 7V11H17C17.5527 11 18 11.4473 18 12C18 12.5527 17.5527 13 17 13H13V17C13 17.2451 12.9121 17.4697 12.7666 17.6436C12.583 17.8613 12.3076 18 12 18C11.4473 18 11 17.5527 11 17V13H7C6.77637 13 6.56934 12.9268 6.40234 12.8027C6.1582 12.6201 6 12.3291 6 12C6 11.4473 6.44727 11 7 11H11V7C11 6.44727 11.4473 6 12 6C12.2617 6 12.5 6.10059 12.6777 6.26465Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default RegisterIcon;
