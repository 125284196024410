const LoggedOut = [
  {
    name: "Cadastro de Produtoras",
    link: "/cadastrar-produtora",
    isSlice: false,
  },
  { name: "Sobre o Site", link: "/sobre-site", isSlice: false },
  { name: "FAQ", link: "/faq", isSlice: false },
  { name: "Regulamento", link: "/regulamento", isSlice: false },
];

const NavLogged = [
  { name: "Nossos Canais", link: "/canais", isSlice: false },
  { name: "Meus Projetos", link: "/meus-projetos", isSlice: false },
  { name: "Cadastrar Projeto", link: "/cadastrar-projetos", isSlice: false },
];

export default {
  LoggedOut,
  NavLogged,
};
