import React from 'react';
import CloseIcon from "../VectorIcons/CloseIcon";
import InfoIcon from "../VectorIcons/InfoIcon";
import "./style/AlertBox.scss"

const AlertBox = (props) => {

  const {closed, type, text, color, children} = props

  return (
    <>
      <div className={`alertBox ${type}`} id="alertBox">

        <div className="closedBox" onClick={() => closed()}>
          <CloseIcon size={16} color={color ? color : "#ffa810"}/>
        </div>

        <InfoIcon color={color ? color : "#ffa810"} size={24}/>
        <p>{text}</p>

        {!!children && children}
      </div>
    </>
  );
};

export default AlertBox;


