import React, { useContext } from "react";
import DownloadIcon from "../../../components/VectorIcons/DownloadIcon";
import AppContext from "../../../context/appContext";
import AttachmentContext from "../../../context/attachmentsContext";

const BlockList = (props) => {
  const { item, condition, _class } = props;

  const appContext = useContext(AppContext);
  const attachmentContext = useContext(AttachmentContext);

  return (
    <div className={`listItem ${_class}`}>
      <span className="fileType">
        {item.attachmentType.name}{" "}
        {item.attachmentType.name === condition && item.chapterNumber}
        {item.attachmentType.name === condition && (
          <span className="blockInfo">Bloco {item.blockNumber}</span>
        )}
      </span>

      <span className="lightText fileName"> {item.fileName} </span>
      <span className="lightText receivedAt">
        {" "}
        {appContext.convertDatePtBr(item.receivedAt)}{" "}
      </span>

      <div className="actions">
        <span
          className="actionItem download"
          onClick={() => attachmentContext.getUrlS3(item.fileId, item.fileName)}
        >
          {" "}
          <DownloadIcon />{" "}
        </span>
      </div>
    </div>
  );
};

export default BlockList;
