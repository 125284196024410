import React from "react";
import "./styles/RegisterSuccess.scss";

const RegisterSuccess = (props) => {
  const {children } = props;

  return (
    <div id="RegisterSuccess">
      {children}
    </div>
  );
};

export default RegisterSuccess;
