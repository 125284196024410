import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";

const Helmet = () => {
  return (
    <ReactHelmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${window.__.auth.analyticsId}`}
      ></script>
    </ReactHelmet>
  );
};

export default Helmet;
