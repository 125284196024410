import React from "react";

const makeList = (list) => (
  <ul>
    {list.map((item, i) => (
      <li key={i}>- {item} </li>
    ))}
  </ul>
);
const makeLink = (target, text, textLink) => {
  return (
    <>
      {text} <a href={target}>{textLink}</a>
    </>
  );
};

const typeProjects = [
  "Título do Projeto",
  "Público-alvo",
  "Número de Episódios;",
  "Minutagem",
  "Prazo estimado de produção da obra",
  "Formato",
  "Tagline",
  "Storyline",
  "Sinopse geral",
  "Sinopse reduzida",
  "Diferencial",
  "Referências",
  "Breve Currículo Roteiristas",
  "Breve Currículo Direção",
  "Roteiro do 1º Episódio",
  "Sinopses dos episódios",
  "Fontes de financiamento (Editais públicos, Recursos próprios, Leis de incentivo à cultura, Coprodutores nacionais, Coprodutores internacionais, Outros)",
  "Proposta de estimativa de custo por episódio e total.•",
];

const typeProjectsVarieties = [
  "Título do Projeto",
  "Público-alvo",
  "Número de Episódios",
  "Minutagem",
  "Prazo estimado de produção da obra",
  "Formato",
  "Tagline",
  "Logline",
  "Sinopse geral",
  "Sinopse reduzida",
  "Diferencial",
  "Referências",
  "Fontes de financiamento (Editais públicos, Recursos próprios, Leis de incentivo à cultura, Coprodutores nacionais, Coprodutores internacionais, Outros)",
  "Proposta de estimativa de custo por episódio e total.",
];

const ListFAQ = [
  {
    title: "Quem pode se inscrever?",
    text: "Empresas produtoras brasileiras independentes registradas na ANCINE e nas respectivas Juntas Comerciais.",
  },
  {
    title: "Como realizar o Cadastro da Produtora?",
    text: makeLink(
      "/cadastrar-produtora",
      "A produtora deve cadastrar seus dados no link Cadastre-se para inscrever seu projeto na página inicial ou clicando",
      "aqui."
    ),
  },
  {
    title: "Qual o tempo de avaliação de cada projeto?",
    text: "90 dias após a finalização da inscrição.",
  },
  {
    title: "Quais são os critérios de seleção?",
    text:
      "Originalidade, potencial dos roteiros, adequação da obra ao público-alvo e posicionamento do canal, " +
      "portfólio de conteúdos já realizados pela produtora e qualificação técnica da equipe.",
  },
  {
    title: "Quais são os campos para apresentação dos projetos de dramaturgia?",
    text: makeList(typeProjects),
  },
  {
    title: "Quais são os campos para apresentação dos projetos de Variedades?",
    text: makeList(typeProjectsVarieties),
  },
  {
    title: "O que devo colocar no orçamento de produção?",
    text: "Entende-se por orçamento de produção da obra audiovisual o conjunto das despesas relativas à sua produção desde o início até a sua conclusão. Deverão ser colocados tanto os custos por episódio quanto o total da obra.",
  },
  {
    title:
      "Como o canal entrará em contato quando um projeto for pré-selecionado?",
    text: "A produtora receberá um e-mail marcando uma reunião presencial no canal.",
  },
  {
    title: "Preciso ter um número na ANCINE para cadastrar projetos?",
    text: "Sim, o número da ANCINE é obrigatório.",
  },
  {
    title: "Quais projetos não serão aceitos?",
    text: "Não serão aceitos projetos que não estiverem de acordo com os critérios de seleção do canal.",
  },
  {
    title:
      "Quanto tempo disponho para preencher todos os campos do regulamento?",
    text: "O tempo que for necessário",
  },
  {
    title: "Por que é recomendado preencher todos os campos do formulário?",
    text: "Recomendamos que todos os campos sejam preenchidos para que o canal possa fazer a avaliação o mais adequadamente possível.",
  },
  {
    title: "Qual regulamento para envio de projetos?",
    text: makeLink("/regulamento", "Veja o regulamento", "aqui."),
  },
];

export default ListFAQ;
