import React from 'react';
import {Link} from "react-router-dom";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import "../../../components/RegisterSuccess/styles/RegisterSuccess.scss"

const Success = (props) => {

  const { title, redirect } = props

  return (
    <div id="RegisterSuccess">
      <div className="container">
        <p className="textSuccessProduction">
          <Link to="/">A Produtora <strong>{title}</strong></Link>foi cadastrado com sucesso!
        </p>

        <p className="subTitleTextSuccessProduction">Após a <strong>Aprovação</strong> do cadastro você receberá um email de <strong>confirmação</strong></p>

        <div className="buttons">
          <SimpleButton
            text="Voltar para o início"
            classButton="buttonGreen extraLarge"
            dataQA="button-prospect-edit"
            clickEvent={ () => redirect()}
          />
        </div>
      </div>
    </div>
  );
};

export default Success;