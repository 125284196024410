import React from 'react';
import RoundBackButton from "../RoundBackButton/RounbBackButton";
import StepCounter from "../StepCounter/StepCounter";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import "./styles/ContentBreadCrumb.scss"

const ContentBreadCrumb = (props) => {

  const { maxStep, currentStep="1", title="Cadastro Produtora", setStep, children } = props

  return (
    <Breadcrumb>
      <div className="registerContainer">
        <div className="infoContainer">
          {currentStep > 1 && (
            <div
              className="backButtonContainer"
              onClick={() => setStep(currentStep - 1)}
            >
              <RoundBackButton color="#17B4E1" backgroundColor="#24233D"/>
            </div>
          )}

          <div className="registerTitle">
            {!children ? (
              <h2>{title}</h2>
            ) : (
              <h2>{children}</h2>
            )}

          </div>
        </div>

        {currentStep < maxStep &&
        <StepCounter currentStep={currentStep} maxStep={maxStep}/>
        }
      </div>
    </Breadcrumb>
  );
};

export default ContentBreadCrumb;