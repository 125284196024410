import React, { useContext, useEffect } from "react";
import "./style/MyProject.scss";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ListItem from "./ListItem/ListItem";
import EmptyProject from "./EmptyProject/EmptyProject";
import Button from "../../components/Button/Button";
import RegisterIcon from "../../components/VectorIcons/RegisterIcon";
import ProjectContext from "../../context/projetsContext";

const MyProject = (props) => {
  const projectContext = useContext(ProjectContext);

  useEffect(() => {
    projectContext.getProjects();
    // eslint-disable-next-line
  }, []);

  if (projectContext.loading) return <div id="MyProject" />;

  return (
    <div id="MyProject">
      <Breadcrumb>
        <p className="title">Meus Projetos</p>
        <Button
          text="Cadastrar Novo Projeto"
          toValidate={true}
          isValidForm={true}
          classButton="buttonBlue large"
          typeButton="button"
          dataQA="button-prospect-new-save"
          clickEvent={() => props.history.push(`/cadastrar-projetos`)}
        >
          <RegisterIcon color="#ffffff" size={24} />
        </Button>
      </Breadcrumb>

      <div className="container">
        {!!projectContext?.list?.length ? (
          <ListItem data={projectContext?.list} />
        ) : (
          <EmptyProject />
        )}
      </div>
    </div>
  );
};

export default MyProject;
