import React from "react";
import "./styles/HelpDesk.scss";

const HelpDesk = () => {
  return (
    <div id="HelpDesk">
      <p>
        Problemas com o sistema:
        <br />
        Help Desk Globo - 21 3004-3911
      </p>
    </div>
  );
};

export default HelpDesk;
