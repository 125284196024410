import React, { useContext } from "react";
import "./styles/CookieApproval.scss";
import SimpleButton from "../SimpleButton/SimpleButton";
import CookiesContext from "../../context/cookiesContext";
import AppContext from "../../context/appContext";
import ScriptBoxCaller from "../ScriptBoxCaller/ScriptBoxCaller";
import ScriptBox from "../ScriptBox/ScriptBox";
import RegulationAndTerms from "../RegulationAndTerms/RegulationAndTerms";

const CookieApproval = () => {
  const cookiesContext = useContext(CookiesContext);
  const appContext = useContext(AppContext);

  const handleApproveCookies = () => {
    cookiesContext.setIsCookiesAllowed(true);
  };

  if (cookiesContext.isCookiesAllowed) return <div />;

  return (
    <>
      <section className="cookieApproval">
        <div className="approvalContainer">
          <span className="message">
            {`Nosso site usa cookies para melhorar a sua experiência e coletar
          estatísticas de uso. Ao continuar você concorda com a nossa `}
            <ScriptBoxCaller
              callerText="política de privacidade"
              event={() => appContext.setCurrentStepBox("showPrivacyPolicies")}
            />
            {"."}
          </span>

          <SimpleButton
            text="Prosseguir"
            classButton="buttonBlue large"
            clickEvent={handleApproveCookies}
          />
        </div>
      </section>

      {appContext.current.step === "showPrivacyPolicies" && (
        <ScriptBox
          open={appContext.current.step === "showPrivacyPolicies"}
          title="Termos de Uso"
          size="large"
          removePadding={true}
        >
          <div className="contentTerms">
            <RegulationAndTerms
              bgColor="#f1f2ff"
              goToRef="privacyPoliciesRef"
            />
          </div>
        </ScriptBox>
      )}
    </>
  );
};

export default CookieApproval;
