import React from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ListChannels from "./ListChannels/ListChannels";
import "./style/Channels.scss";

const Channels = (props) => {
  return (
    <div id="Channels">
      <Breadcrumb>
        <p className="title">Nossos Canais</p>
      </Breadcrumb>
      <p className="description">
        Conheça nossos canais e suas caracteristicas
      </p>
      <ListChannels />
    </div>
  );
};

export default Channels;
