import React, { useEffect } from "react";
import {useHistory} from "react-router-dom";

const Logout = (props) => {

  const history = useHistory();

  useEffect(() => {
    window.localStorage.removeItem("accesstoken");
    history.push("/")
    // eslint-disable-next-line
  }, []);

  return <div />;
};

export default Logout;
