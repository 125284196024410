import React, { useState, useContext, useCallback, useEffect } from "react";
import "./styles/ChangePassword.scss";
import AppContext from "../../../context/appContext";
import Fieldset from "../../../components/Fieldset/Fieldset";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import { putService } from "../../../services/script-api";

const ChangePassword = () => {
  const [passwords, setPasswords] = useState({
    current: "",
    new: "",
    confirm: "",
  });
  const [isError, setIsError] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const appContext = useContext(AppContext);

  const fields = [
    { label: "Senha atual", key: "current" },
    { label: "Nova senha", key: "new" },
    { label: "Confirmar nova senha", key: "confirm" },
  ];

  const onChange = ({ key, value }) => {
    setPasswords((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const submit = () => {
    appContext.setShowGlassLoading(true);

    const putObject = {
      oldPassword: passwords.current,
      newPassword: passwords.confirm,
    };

    putService("user/password", putObject)
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Senha alterada com sucesso!");
        appContext.setCurrentStepBox("closed");
      })
      .catch((error) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Erro ao alterar senha", "error");
      });
  };

  const verifyNewPassword = useCallback(() => {
    const isFilled = passwords.new && passwords.confirm;
    const isEqual = passwords.new === passwords.confirm;
    if (isFilled && !isEqual) {
      return setIsError({ current: false, new: true, confirm: true });
    }
    return setIsError({ current: false, new: false, confirm: false });
  }, [passwords.new, passwords.confirm]);

  const newPasswordIsValid =
    passwords.new === passwords.confirm && passwords.new.length >= 12;

  const numbersRegex = /^(?=.*\d)/m;
  const upperCaseRegex = /^(?=.*[A-Z])/m;
  const lowerCaseRegex = /^(?=.*[a-z])/m;
  const specialCharsRegex = /^(?=(?:.*[!@#$%^&*()\-_=+{};:,<.>]){1,})/m;

  const numbersRegexIsValid = numbersRegex.test(passwords.confirm);
  const upperCaseRegexIsValid = upperCaseRegex.test(passwords.confirm);
  const lowerCaseRegexIsValid = lowerCaseRegex.test(passwords.confirm);
  const specialCharsRegexIsValid = specialCharsRegex.test(passwords.confirm);

  const newPasswordValidation =
    newPasswordIsValid &&
    numbersRegexIsValid &&
    upperCaseRegexIsValid &&
    lowerCaseRegexIsValid &&
    specialCharsRegexIsValid;

  const passwordPolicies = [
    { policy: "12 caracteres ou mais", valid: newPasswordIsValid },
    {
      policy: "Ao menos 01 letra maiúscula",
      valid: upperCaseRegexIsValid,
    },
    {
      policy: "Ao menos 01 letra minúscula",
      valid: lowerCaseRegexIsValid,
    },
    {
      policy: "Ao menos 01 número",
      valid: numbersRegexIsValid,
    },
    {
      policy: "Ao menos 01 caractere especial (Exemplos: @, !, #, $)",
      valid: specialCharsRegexIsValid,
    },
    {
      policy: "Atingir a complexidade mínima",
      valid: newPasswordValidation,
    },
  ];

  useEffect(() => {
    const hasValue = passwords.new && passwords.confirm;
    const equalLength = passwords.new.length === passwords.confirm.length;
    if (hasValue && equalLength) verifyNewPassword();
  }, [passwords.new, passwords.confirm, verifyNewPassword]);

  return (
    <div className="changePassword">
      <div className="validationContainer">
        <span className="policiesTitle">Política de Senha</span>

        <ul className="policiesList">
          {passwordPolicies.map(({ policy, valid }) => (
            <li key={policy} className={`policyItem ${valid ? "valid" : ""}`}>
              {policy}
            </li>
          ))}
        </ul>
      </div>

      <div className="fieldsContainer">
        {fields.map(({ key, label }) => (
          <Fieldset
            key={key}
            label={label}
            name={label}
            type="password"
            autoComplete="off"
            placeholder=""
            handleChange={(ev) =>
              onChange({ key: key, value: ev.target.value })
            }
            value={passwords[key]}
            handleBlur={verifyNewPassword}
            isError={isError[key]}
            msgError="Senhas não coincidem"
          />
        ))}
      </div>

      <BoxFooter>
        <SimpleButton
          text="Alterar senha"
          classButton="buttonGreen large"
          clickEvent={submit}
          isValidForm={passwords.current && newPasswordValidation}
          toValidate={true}
        />
      </BoxFooter>
    </div>
  );
};

export default ChangePassword;
