import React, {useEffect, useState} from 'react'
import './styles/UploadService.scss'
import Dropzone from 'react-dropzone'
import UploadingProgress from '../../components/UploadProgress/UploadingProgress'
import UploadedFile from '../../components/UploadedFile/UploadedFile'
import {
  getService,
  putS3File,
  cancelRequest,
} from '../../services/script-api'
import UploadIcon from '../VectorIcons/UploadIcon'
import Button from '../Button/Button'
import AlertBox from '../AlertBox/AlertBox'

const FilesNotValid = (props) => {

  const {data, clear} = props

  return (
    <div className="formatInvalid">
      <AlertBox color="#db1a33" type="error" closed={clear}
                text="A extensão do(s) Arquivo(s) abaixo é inválida. Verifique e tente novamente."
      >
        <ul>
          {data.map((file, index) => {
            return (
              <li key={index}>{file.path}</li>
            )
          })}
        </ul>
      </AlertBox>

    </div>
  )
}

const UploadService = (props) => {
  const {files, handleOnComplete, getDuplicateFiles, thesaurusFileType, thesaurusId} = props

  const [stateFiles, setStateFiles] = useState(files || [])
  const [attachmentsNotValid, setAttachmentsNotValid] = useState([])

  const setFileInfo = (currentFile, index) => {
    const newState = [...stateFiles]
    newState[index] = Object.assign(newState[index], currentFile)
    handleOnComplete(newState)
  }

  const sendFilesToBucket = () => {
    const filesToSend = stateFiles.filter((f) => !f.isSending && !f.canceled)

    return filesToSend.map((file) => {

      file.type = !!file.type ? file.type : 'application/octet-stream'
      file.contentType = !!file.type ? file.type : 'application/octet-stream'
      file._meta.contentType = !!file.contentType
        ? file.contentType
        : 'application/octet-stream'

      if (!file.canceled && !!stateFiles[stateFiles.indexOf(file)]) {
        const newState = [...stateFiles]
        newState[newState.indexOf(file)].isSending = true
        setStateFiles(newState)
      } else return null

      return new Promise((resolve, reject) => {
        getService('attachment/presignedurl/upload', {
          fileName: file._meta.name,
          contentType: !!file._meta.type
            ? file.contentType
            : 'application/octet-stream',
        })
          .then((res) => {
            file.fileId = res.data.fileId
            file._meta.tempFileName = res.data.fileId

            return putS3File(res.data.url, file, handleProgress)
              .then(async () => {
                await downloadFile(file)
                resolve()
              })
              .catch(resolve)
          })
          .catch(resolve)
      })
    })

  }

  const handleProgress = (event, file) => {
    let total = event.total
    let completed = event.loaded

    if (completed <= total) {
      let percent = Math.round((completed * 100) / total)

      if (completed <= 100) setFileInfo(file)

      setStateFiles((prevState) =>
        prevState.map((item) =>
          item.originalName === file.originalName
            ? {
              ...item,
              completed: percent,
              isSent: percent >= 100,
            }
            : item
        )
      )
    }
  }

  const downloadFile = (file) => {
    getService(`attachment/presignedurl/download?fileId=${file._meta.tempFileName}`)
      .then((res) => {
        setStateFiles((prevState) =>
          prevState.map((item) =>
            item.originalName === file.originalName
              ? {
                ...item,
                url: res.data.url,
                isSent: true,
              }
              : item
          )
        )
      })
      .catch((e) => {
        console.log('error', e)
      })
  }

  const DropDownFile = (sentResource) => {
    handleDrop(sentResource, (newItens) =>
      setStateFiles([...stateFiles, ...newItens])
    )
  }

  let isValidFiles = ['pdf', 'doc', 'fdx', 'docx', 'pptx',
    'rtf', 'mp4', 'jpg', 'ppt', 'jpeg', 'png', 'msg', 'mov',
    'ppsx', 'odt', 'tiff', 'xlsm', 'xlsx', 'wav',
    'm4v', 'mp3', 'dotx', 'txt', 'mpeg', 'pages', 'pptm',
    'ogg', 'potx', 'svg']

  const splitExtension = (name) => {
    let a = name.split('.')
    let length = a.length
    return a[length - 1]
  }

  const handleDrop = (files, callback) => {
    const newFiles = []
    let duplicates = []
    let newState = []

    Array.from(files).forEach((item) => {
      if (!isValidFiles.includes(splitExtension(item.path).toLowerCase())) {
        newState.push(item)
      } else {
        let filter = stateFiles.filter((file) => file.originalName === item.name)
        duplicates.push(filter)

        if (filter.length === 0) {
          newFiles.push({
            originalName: item.name,
            sentAt: item.lastModified,
            type: item.type,
            completed: 0,
            isSent: false,
            _meta: item,
          })
        } else {
          getDuplicateFiles(duplicates)
        }
      }
    })

    setAttachmentsNotValid(newState)
    handleOnComplete(newFiles)
    callback(newFiles)
  }

  const removeFile = (file) => {
    let newState = [...stateFiles]
    newState = newState.filter(
      (f) => f._meta.tempFileName !== file._meta.tempFileName
    )
    setStateFiles(newState)
    handleOnComplete(stateFiles)
  }

  const cancelRequestUpload = (file) => {
    cancelRequest(file)

    setStateFiles((prevState) =>
      prevState.map((item) =>
        item.originalName === file.originalName
          ? {
            ...item,
            isSending: false,
            canceled: true,
          }
          : item
      )
    )
  }

  const resendRequestUpload = (file) => {
    setStateFiles((prevState) =>
      prevState.map((item) =>
        item.originalName === file.originalName
          ? {
            ...item,
            completed: 0,
            canceled: false,
          }
          : item
      )
    )
  }

  const setClearAttachments = () => setAttachmentsNotValid([])

  useEffect(() => {
    sendFilesToBucket()
    handleOnComplete(stateFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFiles])

  return (
    <div className="uploadService">
      {!!attachmentsNotValid.length &&
      <FilesNotValid clear={setClearAttachments} data={attachmentsNotValid}/>
      }
      {!!stateFiles &&
      stateFiles.map((f, i) => {
        return (
          <div key={i}>
            {f.completed >= 0 && f.completed !== 100 && (
              <UploadingProgress
                isUploading={f.isSending}
                percentage={f.completed}
                fileName={f.originalName}
                handleCancel={cancelRequestUpload}
                canceled={!!f.canceled}
                resend={resendRequestUpload}
                handleRemove={removeFile}
                file={f}
              />
            )}

            {f.completed >= 100 && (
              <UploadedFile
                fileName={f.originalName}
                versionNumber={parseInt(1)}
                thesaurusFileType={thesaurusFileType}
                thesaurusId={thesaurusId}
                handleChangeDate={() => {}}
                value={1}
                file={f}
                handleRemove={removeFile}
                setFileInfo={setFileInfo}
                index={i}
              />
            )}
          </div>
        )
      })}

      <Dropzone onDrop={DropDownFile}>
        {({getRootProps, getInputProps}) => (
          <div className="dropzoneContainer">
            <div {...getRootProps()} className="rootDropzone">
              <input {...getInputProps()} />
              <div className="dropzone">
                <div className="dropzoneDashedBox">
                  <Button
                    text="Escolher arquivos..."
                    classButton="buttonBlue large"
                    iconSide="left"
                  >
                    <UploadIcon/>
                  </Button>

                  <span className="dragTitle">Ou arraste-os para cá</span>
                  <span className="dragMaxTitle">
                    {' '}
                    Tamanho máximo: 5GB por arquivo{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  )
}

export default UploadService
