import React, { useState } from "react";
import "./styles/EditPresentation.scss";
import Textarea from "../../../components/Textarea/Textarea";
import BoxFooter from "../../../components/BoxFooter/BoxFooter";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";

const EditPresentation = (props) => {
  const [presentation, setPresentation] = useState("");

  return (
    <div className="editPresentation">
      <label className="presentationLabel">
        Breve relato/currículo sobre a sua experiência no mercado audiovisual e
        3 últimos trabalhos realizados:
      </label>

      <Textarea
        handleChange={(e) => setPresentation(e.target.value)}
        value={presentation}
        name="apresentação"
      />

      <BoxFooter>
        <SimpleButton
          text="Salvar"
          classButton="buttonGreen large"
          clickEvent={() => {}}
        />
      </BoxFooter>
    </div>
  );
};

export default EditPresentation;
