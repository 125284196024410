import React, { useContext, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import "./styles/ProjectSheet.scss";
import { Link, useHistory } from "react-router-dom";
import InfoPaper from "./Componentes/InfoPaper/InfoPaper";
import TagStatus from "../../components/TagStatus/TagStatus";
import ProjectSheetTitle from "./Componentes/Title/ProjectSheetTitle";
import ContentInfo from "./Componentes/ContentInfo/ContentInfo";
import InfoCards from "./Componentes/InfoCards/InfoCards";
import ListAttachments from "../../components/ListAttachments/ListAttachments";
import ListVideos from "./Componentes/ListVideos/ListVideos";
import Financing from "./Componentes/Financing/Financing";
import EditIcon from "../../components/VectorIcons/EditIcon";
import AppContext from "../../context/appContext";
import ProjectContext from "../../context/projetsContext";
import TableLoading from "../../components/TableLoading/TableLoading";
import TagsProject from "../../components/TagsProject/TagsProject";
import RegulationAndTerms from "../../components/RegulationAndTerms/RegulationAndTerms";
import ScriptBox from "../../components/ScriptBox/ScriptBox";
import TypeIconReturner from "../../components/TypeIconReturner/TypeIconReturner";
import DeleteIcon from "../../components/VectorIcons/DeleteIcon";
import AttachmentIcon from "../../components/VectorIcons/AttachmentIcon";
import TableListFiles from "../../components/TableFileList/TableListFiles";
import ConfirmationBox from "../../components/ConfirmationBox/ConfirmationBox";
import { putService } from "../../services/script-api";
import { sendEvent } from "../../services/GoogleAnalytics/methods";

const ProjectSheet = (props) => {
  const { id, params } = props;

  const history = useHistory();

  const appContext = useContext(AppContext);
  const projectContext = useContext(ProjectContext);

  const returnType = {
    dramaturgia: "dramaturgy",
    variedades: "variety",
  };

  const headerList = [
    { label: "Nome", className: "large" },
    { label: "Contato", className: "large contactsHeader" },
    { label: "", className: "editItem" },
  ];

  const deleteProject = (id) => {
    let obj = {
      projectId: id,
      status: 5,
      justification: "",
    };

    putService(`project/status`, obj)
      .then((res) => {
        sendEvent(`Projeto de ${params.type} Externo`, "Exclusão de Projeto");
        appContext.sendMessage("Projeto deletado com sucesso!");
        history.push("/meus-projetos");
        appContext.setCurrentStepBox("closed");
      })
      .catch((e) => {
        appContext.sendMessage("Erro ao deletetar o projeto", "error");
        appContext.setCurrentStepBox("closed");
      });
  };

  useEffect(() => {
    projectContext.getProjectsById(
      returnType[params.type].toLowerCase(),
      params.id
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  let project = projectContext.listProjects.list;
  if (project.length === 0 || projectContext.listProjects.loading)
    return <TableLoading />;

  let cost = project.costAndFinancialInformation;

  return (
    <div id="ProjectSheet">
      <Breadcrumb>
        <div className="contentBreadCrumbProject">
          <div className="contentLogoAndText">
            <TypeIconReturner
              showBackgroundColor={true}
              type={project.projectType.toLowerCase()}
              responsiveIcon={true}
            />
            <p> {project.title} </p>
          </div>

          <div className="backListProject">
            <Link to="/meus-projetos" className="goBack">
              <span>{`« Voltar`}</span>
              <span className="goBackResponsiveText">
                para a lista de projetos
              </span>
            </Link>
          </div>
        </div>
      </Breadcrumb>

      <div id="main">
        <div className="container">
          <div className="leftInfoProject">
            <InfoPaper>
              <div className="contentTitleTag">
                <ProjectSheetTitle title="Status do projeto" />
                <TagStatus
                  name={project.projectStatus?.description}
                  id={project.projectStatus?.id}
                />
                <div
                  className="icoDeleteProject"
                  onClick={() =>
                    appContext.setCurrentStepBox("confirmDeleteProject")
                  }
                >
                  <DeleteIcon color="red" />
                </div>
              </div>
              {project.projectStatus?.id === 2 && (
                <p className="subTitleTag">
                  Aguarde. Seu projeto está sendo avaliado por nossos
                  especialistas.
                </p>
              )}
              {!!project.channels?.[0]?.justification &&
                project.projectStatus?.id === 4 && (
                  <div className="infoField">
                    <span className="infoName">Justificativa</span>
                    <span className="children">
                      {project.channels?.[0]?.justification}
                    </span>
                  </div>
                )}
            </InfoPaper>

            <div className="contentTitleTagMgBottom" />

            <InfoPaper
              handleClick={() =>
                appContext.setCurrentStepBox("EditBasicProjectSheet")
              }
            >
              <ProjectSheetTitle title="Dados Básicos" />
              <div className="space" />
              <ContentInfo
                label="CANAL"
                value={project.channels[0].channel.name}
              />
              <ContentInfo label="TÍTULO" value={project.title} />
              <ContentInfo label="FORMATO" value={project.format.name} />
              <ContentInfo
                label="EPISÓDIOS"
                value={project.chaptersOrEpisodes}
              />
              <ContentInfo
                label="MINUTAGEM"
                value={`${project?.complementaryData?.minutes} minutos`}
              />
              <ContentInfo
                label="PÚBLICO ALVO"
                value={project?.complementaryData?.targetAudience}
              />
              <ContentInfo label="TAGS">
                <TagsProject tags={project.tags} />
              </ContentInfo>
              <ContentInfo
                label="PRAZO ESTIMADO"
                value={project?.deadline?.name}
              />
            </InfoPaper>

            <InfoPaper>
              <ProjectSheetTitle title="Dados Complementares" />
              <div className="space" />
              <ContentInfo
                label="TAGLINE"
                value={project.complementaryData.tagline}
              />
              <ContentInfo
                label="SINOPSE GERAL DA SÉRIE"
                value={project.complementaryData.summary}
              />
              <ContentInfo
                label="SINOPSE REDUZIDA DA SÉRIE"
                value={project?.complementaryData?.smallSummary}
              />

              <ContentInfo
                label="DIFERENCIAL DA SÉRIE"
                value={`${
                  !!project.complementaryData &&
                  project.complementaryData.differential
                }`}
              />
              <ContentInfo
                label="BREVE CURRÍCULO ROTEIRISTAS"
                value={`${
                  !!project.complementaryData &&
                  project.complementaryData.writerCurriculum
                }`}
              />
              <ContentInfo
                label="BREVE CURRÍCULO DIREÇÃO"
                value={`${
                  !!project.complementaryData &&
                  project.complementaryData.directorCurriculum
                }`}
              />
            </InfoPaper>
          </div>

          <div className="rightInfoProject">
            <InfoCards
              hasBaseboard={true}
              title="Anexos"
              handleEvent={() => appContext.setCurrentStepBox("listAllFiles")}
              countData={project.attachments.length}
            >
              <ListAttachments
                files={project.attachments}
                projectType={project.projectType}
              />
            </InfoCards>

            <InfoCards
              handleEvent={() => {}}
              countData={project.videos.length}
              hasBaseboard={true}
              viewAll="Ver todos os vídeos"
              title="Videos"
            >
              {!!project.videos.length &&
                project.videos.map((video, i) => {
                  return (
                    <React.Fragment key={i}>
                      <ListVideos
                        url={video.url}
                        key={i}
                        title={video.title}
                        subTitle=""
                        password={video.password}
                        platform={
                          !!video.url && !!projectContext.isYoutube(video.url)
                            ? "Youtube"
                            : "Vimeo"
                        }
                        projectType={project.projectType}
                      />
                    </React.Fragment>
                  );
                })}

              {!project.videos.length && (
                <div className="cardContent">
                  <span>Não há nenhum video.</span>
                </div>
              )}
            </InfoCards>

            <InfoCards title="Financiamentos">
              <div className="contentCardsFinancing">
                <React.Fragment>
                  {cost.publicNoteCost > 0 && (
                    <Financing
                      label="EDITAIS PÚBLICOS"
                      value={cost.publicNoteCost}
                    />
                  )}

                  {cost.ownResourcesCost > 0 && (
                    <Financing
                      label="RECURSOS PRÓPRIOS"
                      value={cost.ownResourcesCost}
                    />
                  )}

                  {cost.nationalCoProducerCost > 0 && (
                    <Financing
                      label="CONDUTORES NACIONAIS"
                      value={cost.nationalCoProducerCost}
                    />
                  )}

                  {cost.internationalCoProducerCost > 0 && (
                    <Financing
                      label="CONDUTORES INTERNACIONAIS"
                      value={cost.internationalCoProducerCost}
                    />
                  )}

                  {cost.cultureIncentiveLawCost > 0 && (
                    <Financing
                      label="LEIS DE INCENTIVO A CULTURA"
                      value={cost.cultureIncentiveLawCost}
                    />
                  )}

                  {(!!cost?.others?.key || !!cost?.others?.value) && (
                    <Financing
                      label={cost.others?.key}
                      value={cost.others?.value}
                    />
                  )}
                </React.Fragment>

                {cost.costPerEpisode > 0 && (
                  <Financing
                    label="PROPOSTAS DE ESTIMATIVA DE CUSTO POR EPISÓDIO"
                    value={cost.costPerEpisode}
                  />
                )}

                {cost.totalCost > 0 && (
                  <Financing
                    label="PROPOSTAS DE ESTIMATIVA DE CUSTO TOTAL"
                    value={cost.totalCost}
                  />
                )}
              </div>
            </InfoCards>

            <div
              className="titleTerms"
              onClick={() => appContext.setCurrentStepBox("showTerms")}
            >
              <InfoCards title="Termos de uso" _class="termsOfUse" />
            </div>

            {appContext.current.step === "showTerms" && (
              <ScriptBox
                open={appContext.current.step === "showTerms"}
                title="Termos de Uso"
                size="medium"
                icon={<EditIcon color="#ffffff" />}
                removePadding={true}
              >
                <div className="contentTerms">
                  <div className="headerTerms">
                    A produtora garante que está de acordo com os Termos de Uso
                    do Portal.
                  </div>
                  <RegulationAndTerms bgColor="#f1f2ff" />
                </div>
              </ScriptBox>
            )}
          </div>
        </div>
      </div>

      {appContext.current.step === "confirmDeleteProject" && (
        <ConfirmationBox
          open={appContext.current.step === "confirmDeleteProject"}
          textButton="Excluir Projeto"
          closedBox={() => appContext.setCurrentStepBox("closed")}
          handleEvent={() => deleteProject(project?.id)}
          title="Deseja excluir este projeto?"
          text={`Ao confirmar, o projeto ${project?.title} será deletado.`}
        />
      )}

      {appContext.current.step === "listAllFiles" && (
        <ScriptBox
          open={appContext.current.step === "listAllFiles"}
          title="Anexos"
          size="large"
          icon={<AttachmentIcon />}
          removePadding={true}
        >
          <TableListFiles
            headerList={headerList}
            handleEditFile={() => {}}
            handleConfirmationDeleteFile={() => {}}
            renderCreatedAt={true}
            data={project.attachments}
            type="dramaturgy"
            projectType={project.projectType}
            reload={() => {}}
          />
        </ScriptBox>
      )}
    </div>
  );
};

export default ProjectSheet;
