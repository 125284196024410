import React from 'react';
import "./styles/Title.scss"

const Title = () => {
  return (
    <h2 id="titleH2">
      Portal das Produtoras para Envio e <br />
      Gerenciamento de Projetos do Grupo Globo
    </h2>
  );
};

export default Title;