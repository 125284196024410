import React, { useState, useEffect } from "react";
import "./styles/UploadedFile.scss";
import DeleteIcon from "../VectorIcons/DeleteIcon";

const UploadedFile = (props) => {
  const {
    fileName,
    handleRemove,
    file,
    thesaurusId,
    index,
    setFileInfo,
  } = props;

  let initialDate = new Date();

  const initialState = {
    attachmentTypeId: thesaurusId,
    version: 1,
    receiveAt: new Date(
      initialDate.getTime() + initialDate.getTimezoneOffset() * 60000
    ).toISOString(),
    blockNumber: 1,
    chapterNumber: 0,
  };

  const [state] = useState(initialState);

  useEffect(() => {
    setFileInfo(state, index);
    // eslint-disable-next-line
  }, [state]);

  return (
    <div className="uploadedFileContainer">
      <div className="fileHeader">
        <span className="fileName">{fileName}</span>

        <div className="removeButton" onClick={() => handleRemove(file)}>
          <DeleteIcon color="#000" />
        </div>
      </div>
    </div>
  );
};

export default UploadedFile;
