import React from "react";
import tvgImg from "../../../assets/channels/tvg.svg";
import gntImg from "../../../assets/channels/gnt.svg";
import offImg from "../../../assets/channels/off.svg";
import viuImg from "../../../assets/channels/viu.svg";
import bisImg from "../../../assets/channels/bis.svg";
import vivaImg from "../../../assets/channels/viva.svg";
import gloobImg from "../../../assets/channels/gloob.svg";
import multishowImg from "../../../assets/channels/multishow.svg";
import universalImg from "../../../assets/channels/universal.svg";
import gloobinhoImg from "../../../assets/channels/gloobinho.svg";
import globoplayImg from "../../../assets/channels/globoplay.png";
import studiouniversalImg from "../../../assets/channels/studiouniversal.png";
import syfyImg from "../../../assets/channels/syfy.png";
import universalmaisImg from "../../../assets/channels/universalmais.jpg";
import modoviagemImg from "../../../assets/channels/mv.png";

const mockChannels = [
  {
    logo: gntImg,
    text: (
      <>
        <p>
          O GNT é uma marca multiplataforma, que busca inspirar e é inspirada
          pelas histórias das pessoas. Histórias de gente que ama comer, cuidar
          da casa, da autoestima. Histórias de gente que trabalha e viaja para
          valer, gosta de um bom papo e, principalmente, descontrair.
        </p>
        <br />
        <p>
          A partir do diálogo, queremos promover discussões relevantes para a
          sociedade.
        </p>
        <p>
          Você pode acessar esse e todos os outros produtos do GNT através das
          diversas plataformas da marca.
        </p>
      </>
    ),
    name: "GNT",
  },
  {
    logo: modoviagemImg,
    text: (
      <>
        <p>
          Lançado em 2007, o canal passou por diversas transformações e agora
          terá novas temáticas e destinos, abrindo espaço para descobertas.
        </p>
        <br />
        <p>
          Na semana de estreia, atrações como “A Vida é uma Passagem”,
          “Vinhos.Br” e “Expedições” chegam à grade para se unirem a programas
          já exibidos, entre eles “Brasil Visto de Cima”, “Destino Certo” e
          “Hotéis Incríveis”.
        </p>
        <br />
        <p>
          Com o conceito “experimente o mundo”, o Modo Viagem tem seis editorias
          de conteúdo: gastronomia, ao ar livre, visto de cima, guia de viagem,
          hospedagem e cultura. A identidade visual segue uma estética mais
          clean e simples, com tons neutros. Para a construção da nova marca,
          atributos como leveza, simplicidade e versatilidade foram a fonte de
          inspiração.
        </p>
        <br />
        <p>
          Com programas para todos os gostos e destinos, o Modo Viagem conta com
          estreias nacionais, como “Ruas Brasil Afora”, apresentado por Rodrigo
          Ruas, e a nova temporada de Hotéis Incríveis, além de atrações
          internacionais, como Minha Itália e Caminhos do Sorvete. Independente
          da viagem, o que a gente busca é um momento de liberdade, relaxamento
          e fuga da rotina. E é isso que temos na programação do Modo Viagem.
        </p>
      </>
    ),
    name: "Modo Viagem",
  },
  {
    logo: vivaImg,
    text: (
      <>
        <p>
          O VIVA é uma marca multiplataforma, e sua programação exibe um sucesso
          atrás do outro. Voltado principalmente para o público adulto e querido
          por toda a família, o canal de entretenimento e variedades promove o
          encontro de diferentes gerações desde sua estreia, em maio de 2010.
        </p>
        <br />
        <p>
          Com uma grade linear e de fácil identificação, o VIVA reúne os
          programas nacionais que marcaram época, conteúdos internacionais
          consagrados, produções próprias e coproduções com o mercado nacional
          independente, renovando diariamente a emoção e a alegria em assistir
          televisão.
        </p>
        <br />
        <p>
          Novelas, minisséries, seriados, humorísticos e reality shows que você
          quer relembrar, ou assistir pela primeira vez, estão na grade do Viva
          e na memória afetiva dos espectadores. Com perfil de canal aberto
          dentro da TV por assinatura, o VIVA valoriza o talento brasileiro e a
          memória nacional.
        </p>
      </>
    ),
    name: "VIVA",
  },
  {
    logo: gloobImg,
    text: (
      <>
        <p>
          Um mundo de diversão para crianças, a qualquer hora e em qualquer
          lugar.
        </p>
        <p>
          Esse é o Gloob, mais do que um canal de TV por assinatura, uma marca
          multiplataforma de entretenimento que oferece conteúdo infantil de
          alta qualidade nas mais diversas plataformas - TV, VOD, redes sociais,
          games, aplicativos, streamings de música, eventos e produtos
          licenciados.
        </p>
        <br />
        <p>
          Disponível também no Globoplay + Canais Ao Vivo - plataforma digital
          de streaming da Globo -, o canal infantil tem DNA 100% brasileiro,
          investe e acredita em boas histórias e em produções nacionais e
          internacionais de alta qualidade, nas quais a criança se reconheça
          como protagonista.
        </p>
        <p>Os canais Gloob e Gloobinho compõem a Unidade Infantil da Globo.</p>
      </>
    ),
    name: "Gloob",
  },
  {
    logo: multishowImg,
    text: (
      <>
        <p>
          Com uma programação descontraída e diversificada, focada em produções
          nacionais , o Multishow informa, entretém, antecipa tendências de
          comportamento, cria linguagens e experimenta novos formatos e
          conteúdos, acompanhando de perto as transformações de um público que
          leva a vida sem roteiro.
        </p>
        <br />
        <p>
          No ar há 21 anos na TV por assinatura brasileira, o Multishow
          ultrapassou os limites da televisão e produz conteúdo exclusivo também
          para a web.
        </p>
        <p>
          No site http://www.multishow.com.br , são disponibilizados vídeos e
          reportagens sobre a programação, além de séries inéditas, notícias do
          universo jovem e transmissões exclusivas de shows e eventos.
        </p>
      </>
    ),
    name: "MultiShow",
  },
  {
    logo: bisImg,
    text: (
      <>
        <p>
          O canal traz 24 horas do melhor da música, com performances inéditas,
          shows ao vivo, videoclipes, biografias, documentários exclusivos e
          séries musicais.
        </p>
        <br />
        <p>
          O BIS é o ponto de encontro de quem é louco por música. Lançado em
          2009, o canal traz uma programação diversificada, plural e abrangente,
          reunindo atrações nacionais e internacionais para todos os gostos e
          idades – do rock ao eletrônico, do pop ao jazz, do reggae ao blues, da
          MPB aos ritmos sertanejos e regionais do país.
        </p>
        <p>
          No site http://www.canalbis.com.br , são disponibilizados vídeos,
          fotos e matérias sobre a programação. Conteúdo inédito das séries e
          dos documentários da produção nacional, além de transmissões ao vivo e
          exclusivas de shows e eventos.
        </p>
        <br />
        <p>BIS - musica em alta!</p>
      </>
    ),
    name: "Bis",
  },
  {
    logo: universalImg,
    text: (
      <>
        <p>
          O Universal TV. é um dos canais líderes de audiência da TV por
          assinatura e exibe grandes sucessos de audiência como Law & Order:
          SVU, as Franquias Chicago (Fire, P.D. e Med) e FBI (FBI, Most Wanted e
          International).
        </p>
        <br />
        <p>
          O foco da programação reforça o conceito de que os protagonistas das
          séries e dos filmes, com suas personalidades marcantes, são o ponto
          central de uma boa história, levando o melhor do entretenimento aos
          assinantes.
        </p>
        <br />
        <p>
          O Universal TV faz parte do portfólio da NBCUniversal Brasil,
          joint-venture entre os gigantes do entretenimento Globo e
          NBCUniversal.
        </p>
        <br />
        <p>
          A NBCUniversal Brasil realiza a gestão dos canais de TV por assinatura
          Universal TV, Studio Universal e SYFY; do serviço de streaming
          Universal+ e do canal de YouTube Amo Séries em território nacional.
        </p>
        <br />
        <p>
          Em nossas produções originais, buscamos projetos de séries com
          identidade brasileira e boas histórias, com destaque para a
          diversidade do nosso país.
        </p>
        <br />
        <p>
          Procuramos enredos potentes, com protagonistas complexos e
          carismáticos, que consigam superar as adversidades do dia a dia e
          inspirar os nossos telespectadores. Para oportunidades de
          licenciamento de conte údo, favor entrar em contato com
          universalprojetos@nbcuni.com.br.
        </p>
      </>
    ),
    name: "Universal",
  },
  {
    logo: offImg,
    text: (
      <>
        <p>
          Ação, aventura, adrenalina e natureza. Tudo isso retratado em lindas
          imagens, cenários paradisíacos, trilhas sonoras incríveis e com
          personagens que encaram experiências de tirar o fôlego.
        </p>
        <br />
        <p>
          Na grade de programação do OFF, produções nacionais e internacionais
          são protagonizadas por pessoas comuns, apaixonadas por natureza, e por
          atletas que são referência em suas modalidades – seja no ar, agua,
          terra ou neve.
        </p>
      </>
    ),
    name: "Off",
  },
  {
    logo: viuImg,
    text: (
      <>
        <p>
          Focada em projetos digitais, a Viu tem como diferencial a facilidade
          com que cria propostas multiplataforma.
        </p>
        <p>
          Além de estar envolvida com o reposicionamento dos canais da Globosat
          nas diferentes plataformas de distribuição de vídeo (Youtube,
          Facebook, Instagram... You name it!), a Viu oferece novas janelas para
          talentos digitais e parceiros – uma oportunidade sem igual de conectar
          marcas à conteúdos originais e boas histórias.
        </p>
        <br />
        <p>
          Nossa atuação vai bem além de uma Network. Somos uma espécie de
          broadcaster-produtora-agregadora digital que compreende o conteúdo de
          forma 360, visando atuar nas mais diversas áreas e entendendo a
          demanda em função da plataforma, no digital e na TV.
        </p>
        <br />
        <p>
          Já trabalhamos com influenciadores como Whindersson Nunes, Felipe
          Neto, Mauricio Meirelles, Bruna Louise e Evelyn Regly. Inovação,
          criatividade e liberdade são nossos pilares, e acreditamos que todos
          são capazes de criar e agregar, porque tem muita gente a fim de fazer,
          aparecer, vender e produzir.
        </p>
        <br />
        <p>Quer embarcar nessa também? Pode vir com a gente, Viu?</p>
      </>
    ),
    name: "Viu",
  },
  {
    logo: gloobinhoImg,
    text: (
      <>
        <p>
          Um ambiente seguro, afetuoso e divertido que estimula a imaginação, a
          criatividade e o desenvolvimento da criança em idade pré-escolar.
        </p>
        <p>
          Este é o Gloobinho, canal infantil que oferece conteúdos nacionais e
          internacionais de qualidade, produzidos especialmente para esta fase.
        </p>
        <br />
        <p>
          Disponível também no Globoplay + Canais Ao Vivo - plataforma digital
          de streaming da Globo -, o Gloobinho respeita o tempo da infância e,
          ao lado do seu irmão mais velho, o Gloob, ambos compõem a Unidade
          Infantil da Globo.
        </p>
      </>
    ),
    name: "Gloobinho",
  },
  {
    logo: globoplayImg,
    text: (
      <>
        <p>
          O Globoplay é a maior plataforma brasileira de streaming, com oferta
          de conteúdo gratuito e exclusivo para assinantes.
        </p>
        <br />
        <p>
          O serviço reúne diversos conteúdos originais Globo e do mercado
          audiovisual independente, filmes e séries internacionais renomadas,
          dentre elas produções exclusivas, que só estão disponíveis online.
        </p>
        <br />
        <p>
          A plataforma conta ainda com uma oferta completa com os canais
          lineares da Globo através do Globoplay + canais ao vivo, que agrega em
          um só lugar, além da TV Globo, o Multishow, GloboNews, Sportv 1,
          Sportv 2, Sportv 3, GNT, Viva, Gloob, Gloobinho, Off, Bis, Modo
          Viagem, Megapix, Universal TV, Studio Univeral, SYFY, Canal Brasil e
          Futura.
        </p>
        <p>
          Tudo junto, na mais completa e variada oferta de conteúdo para que o
          público acesse a qualquer momento e de onde estiver o que está no ar,
          o que já foi ao ar e o que ainda será exibido.
        </p>
      </>
    ),
    name: "Globoplay",
  },
  {
    logo: tvgImg,
    text: (
      <>
        <p>
        Canal de televisão aberta, gratuita e plural, a TV Globo foi criada em 1965 e, 
        além dessas suas cinco emissoras próprias (Rio de Janeiro, São Paulo, Belo Horizonte, Recife e Brasília), 
        conta com 115 afiliadas independentes que distribuem seu sinal para 99,5% dos domicílios com televisão no Brasil. 
        Líder de audiência, fala todos os dias com cerca de 85 milhões de brasileiros através de um portfólio diverso 
        e de qualidade, que inclui novelas, realities, filmes, shows, programas de variedades, de serviço público, 
        jornalismo e esporte. Exibe mais de 7.500 horas anuais de conteúdo, sendo 55% ao vivo e 83% nacional, 
        original e exclusivo. Veículo de comunicação identificado com o jeito de ser do brasileiro e comprometido com o país 
        e com a cultura nacional, está em constante movimento para acompanhar a sociedade. 
        O talento criativo, a excelência técnica, a profunda sintonia com o público, 
        a qualidade e a relevância de seus conteúdos são seus maiores ativos. E contar boas histórias, a sua maior vocação. 
        Desde 2020 a TV Globo faz parte da Globo, que, em seu processo de transformação digital, 
        integrou o canal de TV aberta aos 26 canais de TV pagos, ao Globoplay e aos produtos e serviços digitais.
        </p>
      </>
    ),
    name: "TV Globo",
  },
  {
    logo: studiouniversalImg,
    text: (
      <>
        <p>
          O Studio Universal é o canal de filmes que oferece uma selecionada
          experiência cinematográfica, com uma variedade de filmes dos maiores
          realizadores do cinema, celebrando a magia do entretenimento e
          exibindo títulos dos diversos gêneros – tudo isso com o carimbo dos
          grandes estúdios de Hollywood.
        </p>
        <br />
        <p>
          Dos blockbusters mais atuais aos eternos clássicos, o Studio Universal
          compartilha experiências emocionais com famílias e amigos de toda
          parte. O canal oferece, ainda, material exclusivo de bastidores e
          pré-estreias.
        </p>
        <br />
        <p>
          O Studio Universal faz parte do portfólio da NBCUniversal Brasil,
          joint-venture entre os gigantes do entretenimento Globo e
          NBCUniversal.
        </p>
        <p>
          A NBCUniversal Brasil realiza a gestão dos canais de TV por assinatura
          Universal TV, Studio Universal e SYFY; do serviço de streaming
          Universal+ e do canal de YouTube Amo Séries em território nacional.
        </p>
      </>
    ),
    name: "Studio Universal",
  },
  {
    logo: universalmaisImg,
    text: (
      <>
        <p>
          O Universal+ reúne o melhor do acervo de séries e filmes dos canais
          Universal TV, Studio Universal e SYFY, em um só lugar, para consumo on
          demand.
        </p>
        <br />
        <p>
          A marca faz parte do portfólio da NBCUniversal Brasil, joint-venture
          entre os gigantes do entretenimento Globo e NBCUniversal.
        </p>
        <br />
        <p>
          O conteúdo do Universal+ é amplo e diversificado, com opções de
          entretenimento para públicos de todos os perfis, idades e gostos.
        </p>
      </>
    ),
    name: "Universal Mais",
  },
  {
    logo: syfyImg,
    text: (
      <>
        <p>
          O SYFY convida o fã de ficção científica a se sentir em casa,
          celebrando todas as nuances do gênero e oferecendo aos apaixonados por
          este universo filmes originais, conteúdos de fantasia, fenômenos
          paranormais, além de super-heróis e cobertura de eventos exclusivos
          nas redes sociais.
        </p>
        <br />
        <p>
          O SYFY faz parte do portfólio da NBCUniversal Brasil, joint-venture
          entre os gigantes do entretenimento Globo e NBCUniversal.
        </p>
        <br />
        <p>
          A NBCUniversal Brasil realiza a gestão dos canais de TV por assinatura
          Universal TV, Studio Universal e SYFY; do serviço de streaming
          Universal+ e do canal de YouTube Amo Séries em território nacional.
        </p>
      </>
    ),
    name: "Syfy",
  },
];

export default mockChannels;
