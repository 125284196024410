import React from 'react';
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Accordion from "../../../components/Accordion/Accordion";
import "./styles/FAQ.scss"

const Faq = () => {
  return (
    <div id="Faq">
      <Breadcrumb>
        <div className="contentBreadCrumbProject">
          <div className="contentLogoAndText">
            <p> Perguntas Frequentes - FAQ </p>
          </div>
        </div>
      </Breadcrumb>

      <div className="container containerFaq">
        <Accordion />
      </div>
    </div>
  );
};

export default Faq;