import React from "react";
import Button from "../../../components/Button/Button";

const Step2 = (props) => {
  const { setStep, data, HandleChangeKeyInObj } = props;

  const isValid = () =>
    !!data?.user?.description && data.user?.description.length <= 1200;
  const isValidCount = () => {
    let c = data?.user?.description?.length;
    let obj = {};

    if (c > 0 && c > 1200) {
      obj["isError"] = true;
      obj["text"] = "Número máximo de caractéres atingido";
    } else {
      obj["isError"] = false;
      obj["text"] = 1200 - c;
    }

    return obj;
  };

  return (
    <div id="Step2">
      <div className="container step2Production contentStepsProduction">
        <h2 className="titleRegisterProduction">
          Breve relato/curriculo sobre a sua experência no mercado audiovisual e
          3 últimos trabalhos realizados.
        </h2>

        <form>
          <div className="formItem large formCount">
            <textarea
              onChange={(e) =>
                HandleChangeKeyInObj("user", "description", e.target.value)
              }
              value={data?.user?.description}
              name="description"
            />
            <div
              className={`count ${
                !!isValidCount().isError ? "errorLabel" : ""
              }`}
            >
              {!!isValidCount().text && isValidCount().text}
            </div>
          </div>

          <div className="contentButton">
            <Button
              text="Avançar"
              toValidate={true}
              isValidForm={isValid()}
              classButton="buttonBlue large"
              typeButton="submit"
              dataQA=""
              clickEvent={() => setStep(3)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Step2;
