import React, {createContext, useReducer} from "react";
import attachmentReducer from "./projectsReducer";
import _ from "lodash"
import {getService} from "../services/script-api";


const initialContext = {
  list: [],
};

const AttachmentContext = createContext();

const AttachmentProvider = ({children}) => {
  const [state] = useReducer(attachmentReducer, initialContext);

  const compiledChapter = (data, type, condition) => {
    let chapter = _.cloneDeep(data)
    let chapterFilter = chapter.filter((e) => e.attachmentType?.name === condition)

    if (type === 'varieties') {
      return _.groupBy(chapterFilter, (u) => u.version)
    } else {
      return _.groupBy(chapterFilter, (u) => u.blockNumber && u.chapterNumber)
    }

  }

  const compileAll = (data, condition) => {
    let groupall = _.cloneDeep(data)
    return _.groupBy(
      groupall.filter(
        (e) =>
          e.attachmentType?.name !== 'Material Complementar' &&
          e.attachmentType?.name !== condition
      ),
      (u) => u.attachmentType?.id
    )
  }

  const compileMaterial = (data, type) => {
    let materialItems = {}
    let compiledMaterial = _.cloneDeep(data)
    let material = compiledMaterial.filter((e) => e.attachmentType?.name === type)
    material.map((e) => materialItems[e.id] = [e])
    return materialItems
  }

  const groupFiles = (files) => _.groupBy(files, (u) => !!u.attachmentType ? u.attachmentType.name : u.fileName)

  const downloadEmployeeData = (url, name) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = name
        a.click()
      })
    })
  }

  const getUrlS3 = (fileId, fileName) => {
    getService(`attachment/presignedurl/download`, {
      fileId: fileId,
    }).then((res) => {
      downloadEmployeeData(res.data.url, fileName)
    })
  }

  return (
    <AttachmentContext.Provider
      value={{
        ...state,
        groupFiles,
        getUrlS3,
        compiledChapter,
        compileAll,
        compileMaterial
      }}
    >
      {children}
    </AttachmentContext.Provider>
  );
};

export {AttachmentContext as default, AttachmentProvider};
